import React from 'react'
import ScreenPreview from "./screenPreview/ScreenPreview"
import "./promo-text.css";
import Config from './config/Config';

class PromoText extends React.Component {
    constructor(...args) {
        super(...args);

        this.state = {
            editMode: true,
            title_maxh: 0,
            title_h_horizontal: 0,
            title_h_vertical: 0,
            conf: {
                background: {
                    backgroundColor: "#76B7FF",
                    textAlign: "center",
                    widthContainer: 520,
                    heightContainer: 293
                },
                title: {
                    text: "",
                    fontFamily: "Roboto",
                    fontSize: 40,
                    bold: "",
                    italic: "",
                    underline: "",
                    lineThrough: "",
                    color: "#FFFFFF",
                    lineHeight: 1,
                    paddingLeftRight: 11,
                    margingBttm: 5
                },
                subtitle: {
                    text: "",
                    fontFamily: "Roboto",
                    fontSize: 25,
                    bold: "",
                    italic: "",
                    underline: "",
                    lineThrough: "",
                    color: "#FFFFFF",
                    lineHeight: 1,
                    paddingLeftRight: 11
                }
            }
        }

        this.initialState = {
            conf: {
                background: {
                    backgroundColor: "#76B7FF",
                    textAlign: "center",
                    widthContainer: 520,
                    heightContainer: 293
                },
                title: {
                    text: "",
                    fontFamily: "Roboto",
                    fontSize: 40,
                    bold: "",
                    italic: "",
                    underline: "",
                    lineThrough: "",
                    color: "#FFFFFF",
                    lineHeight: 1,
                    paddingLeftRight: 11,
                    margingBttm: 5
                },
                subtitle: {
                    text: "",
                    fontFamily: "Roboto",
                    fontSize: 25,
                    bold: "",
                    italic: "",
                    underline: "",
                    lineThrough: "",
                    color: "#FFFFFF",
                    lineHeight: 1,
                    paddingLeftRight: 11
                }
            }
        }
    }

    componentDidMount() {
        fetch(this.props.textFile)
        .then(res => res.json())
        .then((promoTextConf) => {
            this.setState({conf: promoTextConf})
        })
        .catch(err => { throw err });
        
        this.setState({
            title_maxh: document.querySelector(".screen-vertical").offsetHeight - document.querySelector(".screen-vertical textarea.subtitle").offsetHeight,
            title_h_horizontal: document.querySelector(".screen-horizontal textarea.title").scrollHeight,
            title_h_vertical: document.querySelector(".screen-vertical textarea.title").scrollHeight,
        })
    }

    changeConf(newConf) {
        this.setState({
            conf: newConf
        }, () => {this.textareaHeightCalc();})
    }

    changeMode() {
        this.setState({
            editMode: !this.state.editMode
        })
    }
    
    saveMultimediaPromo() {
        const toSave = JSON.stringify(this.state.conf);
        const blob = new Blob([toSave], {type: "application/json"});

        this.props.saveMultimediaPromo(new File([blob], "data.json"), "text_file");
        this.changeMode();
    }

    textareaHeightCalc() {
        this.setState({
            title_h_horizontal: 0,
            title_h_vertical: 0,
        },() => {
            this.setState({
                title_h_horizontal: document.querySelector(".screen-horizontal textarea.title").scrollHeight,
                title_h_vertical: document.querySelector(".screen-vertical textarea.title").scrollHeight,
            }) 
        }) 
    }

    render() {
        const {
            editMode,
            conf,
            title_maxh,
            title_h_horizontal,
            title_h_vertical
        } = this.state;

        const {
            toDeleteText,
            resetDelete
        } = this.props;

        if(toDeleteText) {
            this.setState(this.initialState);
            resetDelete("toDeleteText");
        }

        return (
            <div className="row">
                <ScreenPreview
                    cssClass="screen-horizontal"
                    conf={conf}
                    title_maxh={title_maxh}
                    title_h={title_h_horizontal}
                    textareaHeightCalc={this.textareaHeightCalc.bind(this)}
                    changeConf={this.changeConf.bind(this)}
                    disabled={editMode ? "": " disabled"}
                />
                <ScreenPreview
                    cssClass="screen-vertical"
                    conf={conf}
                    title_maxh={title_maxh}
                    title_h={title_h_vertical}
                    textareaHeightCalc={this.textareaHeightCalc.bind(this)}
                    changeConf={this.changeConf.bind(this)}
                    disabled={editMode ? "": " disabled"}
                />
                <Config
                    conf={conf}
                    changeConf={this.changeConf.bind(this)}
                    saveMultimediaPromo={this.saveMultimediaPromo.bind(this)}
                    editMode={editMode}
                    changeMode={this.changeMode.bind(this)}
                    disabled={editMode ? "": " disabled"}
                />
            </div>
        )
    }
}

export default PromoText