import { Component } from "react";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns'

class DropDownList extends Component {
    render() {
        const {
            cssClass,
            value,
            dataSource,
            change,
            fields,
            id,
            placeholder,
            ref,
            showClearButton
        } = this.props;

        return (
            <DropDownListComponent
                cssClass={cssClass}
                value={value}
                dataSource={dataSource}
                change={change}
                fields={fields}
                id={id}
                placeholder={placeholder}
                ref={ref}
                showClearButton={showClearButton}
            />
        )
    }
}

export default DropDownList