import i18next from "i18next";
import React, { Component } from "react";
import Button from "../../../../../wrapper/button/Button";

class VideoReader extends Component {
    deleteFile(event) {
        event.preventDefault();

        const { deleteFile, videoClass } = this.props;

        deleteFile(videoClass);
    }

    render() {
        const t = (value) => i18next.t(value)

        const { upVideo, videoClass } = this.props;

        return (
            <div className={"mb-3 "+videoClass}>
                <video className="w-100 h-100 rounded-l" controls>
                    <source src={upVideo}/>
                </video>
                <Button 
                    cssClass="delete-file-button m-2 bg-red c-white"
                    onClick={this.deleteFile.bind(this)}
                    value={t("promo.multimedia_delete_vid")}
                />
            </div>
        );
    }
}

export default VideoReader;

