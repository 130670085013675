import { Component } from "react";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";

class NumericTextBox extends Component {
    componentDidMount() {
        const span = document.createElement('span');
        span.className = 'e-input-group-icon e-ddl-icon e-percentage-icon';
        if(document.getElementById(this.props.id))
            document.getElementById(this.props.id).parentNode.appendChild(span)
    }

    render() {
        const {
            cssClass,
            value,
            min,
            max,
            ref,
            id,
            format,
            change,
            showSpinButton
        } = this.props;

        return (
            <NumericTextBoxComponent
                cssClass={cssClass}
                min={min}
                max={max}
                value={value}
                ref={ref}
                id={id}
                format={format}
                change={change}
                showSpinButton={showSpinButton}
            />
        )
    }
}

export default NumericTextBox