import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { Component } from "react";
import { loadCldr } from '@syncfusion/ej2-base';

loadCldr(
    require('cldr-data/supplemental/numberingSystems.json'),
    require('cldr-data/supplemental/weekData.json'),
    require('cldr-data/main/fr-CH/ca-gregorian.json'),
    require('cldr-data/main/fr-CH/numbers.json'),
    require('cldr-data/main/fr-CH/timeZoneNames.json'),
    require('cldr-data/main/en-CH/ca-gregorian.json'),
    require('cldr-data/main/en-CH/numbers.json'),
    require('cldr-data/main/en-CH/timeZoneNames.json'),
    require('cldr-data/main/it-CH/ca-gregorian.json'),
    require('cldr-data/main/it-CH/numbers.json'),
    require('cldr-data/main/it-CH/timeZoneNames.json')
);

class DatePicker extends Component {
    componentDidMount() {
        this.datepicker.locale = this.props.lang+"-CH"
    }

    componentDidUpdate(prevProps) {
        if(prevProps.lang !== this.props.lang)
            this.datepicker.locale = this.props.lang+"-CH"
    }

    render() {
        const { 
            cssClass,
            value,
            id,
            onChange,
            format,
            min,
            max
        } = this.props;

        return (
            <DatePickerComponent
                cssClass={cssClass}
                value={value}
                id={id}
                min={min}
                max={max}
                onChange={onChange}
                format={format}
                showTodayButton={false}
                ref={datepicker => this.datepicker = datepicker}
            />
        )
    }
}

export default DatePicker