import React, { Component } from 'react';
import { ScheduleComponent, ViewsDirective, ViewDirective, Week, WorkWeek, Month, Inject } from '@syncfusion/ej2-react-schedule';
import EventScheduleWeekLarge from './event/EventScheduleWeekLarge';
import EventScheduleWeekSlim from './event/EventScheduleWeekSlim';
import "./schedule.css"
import EventScheduleWeekMedium from './event/EventScheduleWeekMedium';
import QuickInfo from './quickInfo/QuickInfo';
import EventScheduleMonth from './event/EventScheduleMonth';
import Dialog from '../../wrapper/dialog/Dialog';
import { fetchPromotions } from '../../../api/fetch';
import { loadCldr} from '@syncfusion/ej2-base';
import i18next from 'i18next';

loadCldr(
  require('cldr-data/supplemental/numberingSystems.json'),
  require('cldr-data/main/fr-CH/ca-gregorian.json'),
  require('cldr-data/main/fr-CH/numbers.json'),
  require('cldr-data/main/fr-CH/timeZoneNames.json'),
  require('cldr-data/main/en-CH/ca-gregorian.json'),
  require('cldr-data/main/en-CH/numbers.json'),
  require('cldr-data/main/en-CH/timeZoneNames.json'),
  require('cldr-data/main/it-CH/ca-gregorian.json'),
  require('cldr-data/main/it-CH/numbers.json'),
  require('cldr-data/main/it-CH/timeZoneNames.json')
);


export class Schedule extends Component {
    constructor(...args) {
        super(...args);
        
        this.state = {
          data: [],
          start_date: null,
          end_date: null
        }
    }

    componentDidUpdate(prevProps, prevState) {
      if(prevState.start_date !== this.state.start_date || prevState.end_date !== this.state.end_date) {
        this.refreshPromotions()
      }
    }

    async refreshPromotions(start = "", end = "") {
      start = new Date(this.state.start_date)
      end = new Date(this.state.end_date)
      start = start.getFullYear() + "-" + ((start.getMonth()+1) < 10 ? "0"+(start.getMonth()+1) : (start.getMonth()+1)) + "-" + (start.getDate() < 10 ? "0"+start.getDate() : start.getDate());
      end = end.getFullYear() + "-" + ((end.getMonth()+1) < 10 ? "0"+(end.getMonth()+1) : (end.getMonth()+1)) + "-" + (end.getDate() < 10 ? "0"+end.getDate() : end.getDate());

      const result = await fetchPromotions(start, end)

      if(result.promotions) {
        result.promotions.forEach((promotion, index) => {
          result.promotions[index].ends_at = result.promotions[index].ends_at+" 23:59:59"
        })
        this.setState({
          data: result.promotions
        })
      } 
    }

    quickInfoTemplates(props){
      return (
        <Dialog 
          showCloseIcon={true}
          cssClass="quick-info"
          content={
            <QuickInfo 
              history={this.props.history} 
              element={props}
              refreshPromotions={this.refreshPromotions.bind(this)}
            />
          }
          isModal={true}
        />
      )  
    }

    eventTemplateWeek(props){
      if(props.data.count === 1) {
        return (
          <EventScheduleWeekSlim element={props} showImage={false}/>
        )
      }
      else if(props.data.count === 2) {
        return(
          <EventScheduleWeekSlim element={props} showImage={true}/>
        )
      }
      else if(props.data.count > 2 && props.data.count < 4) {
        return(
          <EventScheduleWeekMedium element={props}/>
        )
      }
      else {
        return(
          <EventScheduleWeekLarge element={props}/>
        ) 
      }     
    }

    eventTemplateMonth(props){
      return(
        <EventScheduleMonth element={props}/>
      )  
    }

    complete(args) {
      if(args.requestType === "viewNavigate" || args.requestType === "dateNavigate") { 
        const currentViewDate = this.scheduleObj.getCurrentViewDates()

         this.setState({
          start_date: currentViewDate[0],
          end_date: currentViewDate[currentViewDate.length - 1]
        }) 
      }
    }

    created() { 
        const currentViewDate = this.scheduleObj.getCurrentViewDates()

        this.setState({
          start_date: currentViewDate[0],
          end_date: currentViewDate[currentViewDate.length - 1]
        })

        this.refreshPromotions()
    }

    render() {
      const t = (value) => i18next.t(value)

      return (
      <div className='schedule-control-section'>
        <div className='col-12 control-section'>
          <div className='control-wrapper'>
            <ScheduleComponent
            ref={schedule => this.scheduleObj = schedule}
            actionComplete={this.complete.bind(this)}
            created={this.created.bind(this)}
            rowAutoHeight={true}
            /* selectedDate={Date.now()} */
            timeScale={{ enable: false }}
            cssClass="schedule-cell-dimension"
            quickInfoTemplates={{
              header: null,
              content: this.quickInfoTemplates.bind(this)
            }}
            locale={i18next.language.split("-")[0]+"-CH"}
            eventSettings={{
              allowAdding: false,
              allowEditing: false,
              dataSource: this.state.data,
              fields: {
                startTime: { name: 'starts_at' },
                endTime: { name: 'ends_at' }
              },
            }}
            >
              <ViewsDirective>
                <ViewDirective option='Week' displayName={t("promotions_planning.week")} firstDayOfWeek={1} eventTemplate={this.eventTemplateWeek.bind(this)}/>
                <ViewDirective option='WorkWeek' displayName={t("promotions_planning.work_week")} eventTemplate={this.eventTemplateWeek.bind(this)}/>
                <ViewDirective option='Month' displayName={t("promotions_planning.month")} firstDayOfWeek={1} eventTemplate={this.eventTemplateMonth.bind(this)}/>
              </ViewsDirective>
              <Inject services={[Week, WorkWeek, Month]}/>
            </ScheduleComponent>
          </div>
        </div>
      </div>
    );
  }
}

export default Schedule