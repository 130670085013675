import { Component } from "react";
import { fetchFluiditySiteRealtime, fetchFluiditySiteTotal } from "../../api/fetch";
import "./fluidity-site-detail.css"
import SiteComparison from "./fluidityRestaurant.js/comparison/SiteComparison";
import FluidityRestaurant from "./fluidityRestaurant.js/FluidityRestaurant";
import userRealtimeWhite from "../../images/omino_e_orologio@3x.png"
import userTotalWhite from "../../images/tre_omini@3x.png"
import i18next from "i18next";
import { getCurrentDateWithTZ } from "../../utility/tz";

class FluiditySiteDetail extends Component {
    constructor(...args) {
        super(...args)

        this.state = {
            loaded: false,
            fluiditySiteRealtime: {},
            fluiditySiteTotal: {},
            bgColor: ""
        }
    }

    async componentDidMount() {
        const historyState = this.props.history.location.state
        const currentdate = getCurrentDateWithTZ()
        const fluiditySiteRealtime = await fetchFluiditySiteRealtime(historyState.siteID, historyState.siteName, currentdate)
        const fluiditySiteTotal = await fetchFluiditySiteTotal(historyState.siteID, historyState.siteName, currentdate)

        this.setState({
            fluiditySiteRealtime: fluiditySiteRealtime,
            fluiditySiteTotal: fluiditySiteTotal,
            bgColor: historyState.bgColor,
            loaded: true
        })
    }

    render() {
        const t = (value) => i18next.t(value)

        const { history, loaded, fluiditySiteRealtime, fluiditySiteTotal, bgColor } = this.state;

        if(!loaded) {
            return <div>loading</div>
        }
        else {
            return (
                <>
                    <div className="col-12">
                        <div className={"row pt-2 pb-3"+bgColor}>
                            <div className="col-12">
                                <p className="font-weight-bold font-size-22 c-white" onClick={()=>{this.props.history.push("/fluidity")}}>{"<"}&nbsp;{t("fluidity_site.back")}</p>
                            </div>
                            <div className="col-12">
                                <p className="font-weight-regular font-size-18 c-white">{t("fluidity_site.lunch_dinner")}</p>
                            </div>
                            <div className="col-12 d-flex flex-column">
                                <p className="text-center font-weight-bold font-size-35 c-white">{fluiditySiteRealtime.site.name}</p>
                                <p className="text-center font-weight-regular font-size-24 c-white">{t("fluidity_site.subtitle")}</p>
                            </div>
                            <div className="col-12 d-flex justify-content-center">
                                <div className="col-auto d-flex">
                                    <div className="col-auto">
                                        <img alt="" style={{width:"auto", height:"100px"}} src={userRealtimeWhite}/>
                                    </div>
                                    <div className="col">
                                        <p className="font-weight-bold font-size-20 c-white">{t("fluidity_site.users_realtime")}</p>
                                        <p className="font-weight-regular font-size-72 c-white">{fluiditySiteRealtime.site.users_realtime}</p>
                                    </div>
                                </div>
                                <div className="col-auto d-flex">
                                    <div className="col-auto">
                                        <img alt="" style={{width:"auto", height:"100px"}} src={userTotalWhite}/>
                                    </div>
                                    <div className="col">
                                        <p className="font-weight-bold font-size-20 c-white">{t("fluidity_site.users_total")}</p>
                                        <p className="font-weight-regular font-size-72 c-white">{fluiditySiteTotal.site.users_total}</p>
                                    </div>
                                </div>
                            </div>
                            {fluiditySiteTotal.site.comparison ?
                                <div className="col-12">
                                    <SiteComparison orientation="horizontal" comparison={fluiditySiteTotal.site.comparison}/>
                                </div>
                            :
                                null
                            }
                        </div>
                    </div>
                    <div className="content-page w-100 h-auto bg-ap rounded-l p-0">
                        <FluidityRestaurant history={history} fluiditySiteRealtime={fluiditySiteRealtime} fluiditySiteTotal={fluiditySiteTotal} bgColor={bgColor}/>
                    </div>
                </>
            )
        }
    }
}

export default FluiditySiteDetail