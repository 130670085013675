import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Accordion from "../../wrapper/accordion/Accordion";
import CheckBox from "../../wrapper/checkBox/checkBox";
import Presets from "./Presets";
import Trigger from "./Trigger";
import TypesPromoList from "./TypesPromoList";

class RestaurantsList extends Component {
    constructor(...args) {
        super(...args)

        this.state = {
            firstRender: true
        }
    }

    checkTheBoxPreset(event) {
        const { restaurants } = this.props;

        const checkboxSplitID = event.event.currentTarget.id.split("-")

        restaurants.forEach(restaurant => {
            if(event.checked) {
                if(document.querySelector("div[aria-checked='false'] input[id='restaurant-"+checkboxSplitID[1]+"-"+restaurant.id+"']")) {
                    document.querySelector("div[aria-checked='false'] input[id='restaurant-"+checkboxSplitID[1]+"-"+restaurant.id+"']").parentNode.parentNode.classList.add("site")
                    document.querySelector("div[aria-checked='false'] input[id='restaurant-"+checkboxSplitID[1]+"-"+restaurant.id+"']").parentNode.parentNode.classList.add("disabled")
                    document.querySelector("div[aria-checked='false'] input[id='restaurant-"+checkboxSplitID[1]+"-"+restaurant.id+"']").click()
                }
                if(document.querySelector("div[aria-checked='true'] input[id='restaurant-"+checkboxSplitID[1]+"-"+restaurant.id+"']")) {
                    document.querySelector("div[aria-checked='true'] input[id='restaurant-"+checkboxSplitID[1]+"-"+restaurant.id+"']").parentNode.parentNode.classList.add("site")
                    document.querySelector("div[aria-checked='true'] input[id='restaurant-"+checkboxSplitID[1]+"-"+restaurant.id+"']").parentNode.parentNode.classList.add("disabled")
                }
                if(checkboxSplitID[2] === "All"){
                    document.querySelectorAll("input:not([id$='All'])[id^='"+checkboxSplitID[0]+"-"+checkboxSplitID[1]+"-']").forEach(presetToLock => {
                        presetToLock.parentNode.parentNode.classList.add("site");
                        presetToLock.parentNode.parentNode.classList.add("disabled");
                    })
                    if(document.querySelectorAll(".accordion-site-"+checkboxSplitID[1]+" div[aria-checked='true'] input[id$='-Welcome']").length > 0)
                        document.querySelectorAll(".accordion-site-"+checkboxSplitID[1]+" div[aria-checked='true'] input[id$='-Welcome']").forEach(presetToLock => {
                            presetToLock.click();
                        })
                    if(document.querySelectorAll(".accordion-site-"+checkboxSplitID[1]+" div[aria-checked='true'] input[id$='-Edito']").length > 0)
                        document.querySelectorAll(".accordion-site-"+checkboxSplitID[1]+" div[aria-checked='true'] input[id$='-Edito']").forEach(presetToLock => {
                            presetToLock.click();
                    })
                    if(document.querySelectorAll(".accordion-site-"+checkboxSplitID[1]+" div[aria-checked='true'] input[id$='-Mobile']").length > 0)
                        document.querySelectorAll(".accordion-site-"+checkboxSplitID[1]+" div[aria-checked='true'] input[id$='-Mobile']").forEach(presetToLock => {
                            presetToLock.click();
                        })
                    if(document.querySelectorAll(".accordion-site-"+checkboxSplitID[1]+" div[aria-checked='true'] input[id$='-Landing']").length > 0)
                        document.querySelectorAll(".accordion-site-"+checkboxSplitID[1]+" div[aria-checked='true'] input[id$='-Landing']").forEach(presetToLock => {
                            presetToLock.click();
                        })
                }
                else {
                    document.querySelector("input[id='"+checkboxSplitID[0]+"-"+checkboxSplitID[1]+"-All']").parentNode.parentNode.classList.add("disabled");
                    if(document.querySelectorAll(".accordion-site-"+checkboxSplitID[1]+" div[aria-checked='true'] input[id$='-All']").length > 0)
                        document.querySelectorAll(".accordion-site-"+checkboxSplitID[1]+" div[aria-checked='true'] input[id$='-All']").forEach(presetToLock => {
                            presetToLock.click();
                        })
                }
                if(document.querySelector("input[id='typepromo-"+checkboxSplitID[1]+"-"+restaurant.id+"-"+checkboxSplitID[2]+"']")) {
                    document.querySelector("input[id='typepromo-"+checkboxSplitID[1]+"-"+restaurant.id+"-"+checkboxSplitID[2]+"']").parentNode.parentNode.classList.add("site")
                    document.querySelector("input[id='typepromo-"+checkboxSplitID[1]+"-"+restaurant.id+"-"+checkboxSplitID[2]+"']").parentNode.parentNode.classList.add("disabled")
                }
                
                if(document.querySelector("div[aria-checked='false'] input[id='typepromo-"+checkboxSplitID[1]+"-"+restaurant.id+"-"+checkboxSplitID[2]+"']"))
                    document.querySelector("div[aria-checked='false'] input[id='typepromo-"+checkboxSplitID[1]+"-"+restaurant.id+"-"+checkboxSplitID[2]+"']").click()
            }
            else {
                if(document.querySelectorAll("div[aria-checked='true'] input[id='restaurant-"+checkboxSplitID[1]+"-"+restaurant.id+"']").length > 0 &&
                document.querySelectorAll("div[aria-checked='true'] input[id^='"+checkboxSplitID[0]+"-"+checkboxSplitID[1]+"-']").length === 0) {
                    document.querySelectorAll("div[aria-checked='true'] input[id='restaurant-"+checkboxSplitID[1]+"-"+restaurant.id+"']").forEach(checkboxToClick => {
                        checkboxToClick.parentNode.parentNode.classList.remove("site");
                        checkboxToClick.parentNode.parentNode.classList.remove("disabled")
                    })
                }
                if(checkboxSplitID[2] === "All"){
                    document.querySelectorAll("input:not([id$='All'])[id^='"+checkboxSplitID[0]+"-"+checkboxSplitID[1]+"-']").forEach(presetToLock => {
                        presetToLock.parentNode.parentNode.classList.remove("site");
                        presetToLock.parentNode.parentNode.classList.remove("disabled");
                    })
                }
                else {
                    const divCheckBox = document.querySelector("div[class*='accordion-site-"+checkboxSplitID[1]+"']").querySelectorAll("div[aria-checked='true'][class~='check-restaurants']")
                    if(divCheckBox.length === 0) {
                        document.querySelector("div[class*='accordion-site-"+checkboxSplitID[1]+"']").querySelector("input[id='"+checkboxSplitID[0]+"-"+checkboxSplitID[1]+"-All']").parentNode.parentNode.classList.remove("site");
                        document.querySelector("div[class*='accordion-site-"+checkboxSplitID[1]+"']").querySelector("input[id='"+checkboxSplitID[0]+"-"+checkboxSplitID[1]+"-All']").parentNode.parentNode.classList.remove("disabled");
                    }
                }
                document.querySelector("input[id='typepromo-"+checkboxSplitID[1]+"-"+restaurant.id+"-"+checkboxSplitID[2]+"']").parentNode.parentNode.classList.remove("site")
                document.querySelector("input[id='typepromo-"+checkboxSplitID[1]+"-"+restaurant.id+"-"+checkboxSplitID[2]+"']").parentNode.parentNode.classList.remove("disabled")
                if(document.querySelector("div[aria-checked='true'] input[id='typepromo-"+checkboxSplitID[1]+"-"+restaurant.id+"-"+checkboxSplitID[2]+"']"))
                    document.querySelector("div[aria-checked='true'] input[id='typepromo-"+checkboxSplitID[1]+"-"+restaurant.id+"-"+checkboxSplitID[2]+"']").click()
            }
        })
    }

    checkTheBoxCustom(event) {
        const checkboxSplitID = event.event.currentTarget.id.split("-")

        if(event.checked) {
            if(document.getElementsByClassName("accordion-"+checkboxSplitID[0]+"-"+checkboxSplitID[2]).length !== 0)
                document.getElementsByClassName("accordion-"+checkboxSplitID[0]+"-"+checkboxSplitID[2])[0].classList.remove("d-none")
        }
        else {
            document.getElementsByClassName("accordion-"+checkboxSplitID[0]+"-"+checkboxSplitID[2])[0].classList.add("d-none")
            if(document.querySelectorAll("div[class~='accordion-"+checkboxSplitID[0]+"-"+checkboxSplitID[2]+"'] div[aria-checked='true'] input[id^='typepromo']").length > 0) {
                document.querySelectorAll("div[class~='accordion-"+checkboxSplitID[0]+"-"+checkboxSplitID[2]+"'] div[aria-checked='true'] input[id^='typepromo']").forEach(checked => {
                    checked.click();
                })
            }
            if(document.querySelectorAll("div[aria-checked='true'] input[id^='restaurant-"+checkboxSplitID[1]+"']").length === 0)
                document.querySelector("div[class~='trigger-site-"+checkboxSplitID[1]+"']").classList.add("d-none")
            document.getElementById("restaurant-trigger-"+checkboxSplitID[2]).classList.add("d-none")
        }
    }

    contentTypesPromoList(restaurant) {
        const { tags, siteID, addRestaurant, deleteRestaurant, promo } = this.props

        return (
            <TypesPromoList 
                siteID={siteID} 
                restaurantID={restaurant.id} 
                tags={tags} 
                addRestaurant={addRestaurant} 
                deleteRestaurant={deleteRestaurant}
                promo={promo}
            />
        )
    }

    contentTrigger() {
        const { restaurants, setTrigger, unsetTrigger, promo } = this.props
        this.setState({firstRender: false})

        return <Trigger restaurants={restaurants} setTrigger={setTrigger} unsetTrigger={unsetTrigger} promo={promo}/>
    }

    render() {
        const t = this.props.t

        const { restaurants, tags, siteName, siteID } = this.props

        let areRegionsForRestaurants = false
        const contentsAccordionRestaurant = []
        restaurants.forEach(restaurant => {
            if(restaurant.regions) {
                areRegionsForRestaurants = true
            }
            contentsAccordionRestaurant.push({id:"accordion-restaurant-"+restaurant.id, header: `<p class='font-weight-bold font-size-25 c-opale m-0 my-2'>${restaurant.name+", "+siteName}</p>`, content: this.contentTypesPromoList.bind(this, restaurant)})
        })

        let contentsAccordionTriggerRestaurant = []
        if(this.state.firstRender) {
            contentsAccordionTriggerRestaurant = [{header: "<p class='font-weight-bold font-size-30 c-opale m-0 my-2'>"+t("promo.fluidity_trigger_title")+"</p>", content: this.contentTrigger.bind(this)}]
        }
        return (
            <div className="row px-3 pt-2">
                <div className="col-12">
                    <p className="font-weight-bold font-size-24 c-prussia mb-1">{t("promo.preset_title")}</p>
                </div>
                <div className="col-12">
                    <Presets 
                        cssClass={"check-restaurants "}
                        tags={tags}
                        refID={"site-"+siteID}
                        onClick={this.checkTheBoxPreset.bind(this)}
                    />
                </div>
                <div className="col-12">
                    <p>{t("promo.or")}...</p>
                </div>
                <div className="col-12">
                    <p className="font-weight-bold font-size-24 c-prussia mb-1">{t("promo.preset_title")}</p>
                </div>
                <div className="col-12">
                    <p className="font-weight-bold font-size-18 c-prussia opacity-5 mb-1">{t("promo.custom_restaurant_subtitle")}</p>
                </div>
                <div className="col-6 mb-3">
                    {restaurants.map((restaurant, key) => { 
                        return (
                            <CheckBox
                                cssClass="check-restaurant col-4 p-0 my-2 font-weight-regular font-size-18 c-opale opacity-7"
                                id={"restaurant-"+siteID+"-"+restaurant.id}
                                label={restaurant.name}
                                change={this.checkTheBoxCustom.bind(this)}
                                key={key}
                            />
                        )
                    })}
                </div>
                <Accordion cssClass="type-promo-list border-top d-none" contents={contentsAccordionRestaurant}/>
                {areRegionsForRestaurants ? <Accordion cssClass={"trigger-site-"+siteID+" border-top d-none"} contents={contentsAccordionTriggerRestaurant}/> : null}
            </div>
        )
    }
}

export default withTranslation()(RestaurantsList)