import { Component } from "react";
import DatePicker from "../../wrapper/datePicker/DatePicker";
import MaskedTextBox from "../../wrapper/maskedTextBox/MaskedTextBox";
import TextBox from "../../wrapper/textBox/textBox";
import { Internationalization } from '@syncfusion/ej2-base';
import i18next from "i18next";

class FieldTextPromo extends Component {
    constructor(...args) {
        super(...args)

        this.state = {
            mask: "[0-2][0-9]:[0-5][0-9]",
            minEndDate: null,
            maxStartDate: null,
            promo: this.props.promo
        }

        this.instance = new Internationalization();
    }

    handleTimeChange(event) {
        if(event.nativeEvent.text === "0" || event.nativeEvent.text === "1") {
            this.setState({
                mask: "[0-2][0-9]:[0-5][0-9]"
            })
        }
        else if (event.nativeEvent.text === "2") {
            this.setState({
                mask: "[0-2][0-3]:[0-5][0-9]"
            })
        }

        if(event.value.length === 4) {
            const promo = JSON.parse(JSON.stringify(this.state.promo));

            if(event.target.id === "starts_time_at" && this.state.promo.ends_time_at !== "") {
                if(this.completeTimeFormat(event.value)<this.state.promo.ends_time_at) {
                    promo[event.target.id] = this.completeTimeFormat(event.value);
                }
                else {
                    event.target.value = ""
                    promo[event.target.id] = ""
                }
            } 
            else if (event.target.id === "ends_time_at" && this.state.promo.starts_time_at !== "") {
                if(this.completeTimeFormat(event.value)>this.state.promo.starts_time_at) {
                    promo[event.target.id] = this.completeTimeFormat(event.value);
                }
                else {
                    event.target.value = ""
                    promo[event.target.id] = ""
                }
            }
            else {
                promo[event.target.id] = this.completeTimeFormat(event.value);
            }

            this.setState({promo: promo})
            this.props.setPromo("text", promo)
        }
    }

    handleChange(event) {
        const promo = JSON.parse(JSON.stringify(this.state.promo));
        
        if(event.target.id === "starts_at"){
            const date = new Date(event.value)
            promo[event.target.id] = date.toISOString()
        }
        else if(event.target.id === "ends_at"){
            const date = new Date(event.value)
            promo[event.target.id] = date.toISOString()
        }
        else {
            promo[event.target.id] = event.value
        }

        this.setState({promo: promo})
        this.props.setPromo("text", promo)
    }

    completeTimeFormat(time) {
        const hour = time.substr(0,2);
        const minute = time.substr(2,3);

        return hour+":"+minute
    }

    newDate(date) {
        return new Date(date)
    }

    render() {
        const t = (value) => i18next.t(value)
        const { mask, promo } = this.state;
        return (
            <div className="row mb-5">
                <div className="col-md-7">
                    <div className="row custom-margin custom-padding-5">
                        <div className="col-6">
                            <p className="d-inline-block font-weight-bold font-size-16 c-prussia opacity-5 mt-4 mb-2">{t("promo.start_date")}</p><p className="d-inline-block c-red font-weight-bold font-size-16 mb-2">&nbsp;*</p>
                            <DatePicker id="starts_at" cssClass="e-outline custom-date-promo" max={this.newDate(promo.ends_at)} value={promo.starts_at} format='dd/MM/yyyy' onChange={this.handleChange.bind(this)} lang={i18next.language.split("-")[0]}/>
                        </div>
                        <div className="col-6">
                            <p className="d-inline-block font-weight-bold font-size-16 c-prussia opacity-5 mt-4 mb-2">{t("promo.end_date")}</p><p className="d-inline-block c-red font-weight-bold font-size-16 mb-2">&nbsp;*</p>
                            <DatePicker id="ends_at" cssClass="e-outline custom-date-promo" min={this.newDate(promo.starts_at)} value={promo.ends_at} format='dd/MM/yyyy' onChange={this.handleChange.bind(this)} lang={i18next.language.split("-")[0]}/>
                        </div>
                        <div className="col-6">
                            <p className="d-inline-block font-weight-bold font-size-16 c-prussia opacity-5 mt-4 mb-2">{t("promo.start_time")}</p><p className="d-inline-block c-red font-weight-bold font-size-16 mb-2">&nbsp;*</p>
                            <MaskedTextBox id="starts_time_at" mask={mask} cssClass="e-outline custom-mask-promo" onChange={this.handleTimeChange.bind(this)} value={promo.starts_time_at}/>
                        </div>
                        <div className="col-6">
                            <p className="d-inline-block font-weight-bold font-size-16 c-prussia opacity-5 mt-4 mb-2">{t("promo.end_time")}</p><p className="d-inline-block c-red font-weight-bold font-size-16 mb-2">&nbsp;*</p>
                            <MaskedTextBox id="ends_time_at" mask={mask} cssClass="e-outline custom-mask-promo" onChange={this.handleTimeChange.bind(this)} value={promo.ends_time_at}/>
                        </div>
                        <div className="col-12">
                            <p className="d-inline-block font-weight-bold font-size-16 c-prussia opacity-5 mt-4 mb-2">{t("promo.name")}</p><p className="d-inline-block c-red font-weight-bold font-size-16 mb-2">&nbsp;*</p>
                            <TextBox id="name" cssClass="e-outline m-0" value={promo.name} onChange={this.handleChange.bind(this)}/>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 d-flex flex-column">
                    <div className="row promo-label-div-flex">
                        <div className="col-12">
                            <p className="font-weight-bold font-size-16 c-prussia opacity-5 mt-4 mb-2">{t("promo.description")}</p>
                        </div>
                    </div>
                    <div className="row promo-textarea-div-flex">
                        <div className="col-12">
                            <TextBox id='description' multiline={true} cssClass="e-outline h-100 " value={promo.description} onChange={this.handleChange.bind(this)}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FieldTextPromo