import { Component } from "react";
import Button from "../../../../wrapper/button/Button";
import Background from "./background/Background";
import Text from "./text/Text";
import whiteCheck from "../../../../../images/white_check@3x.png"
import './config.css'
import i18next from "i18next";

class Config extends Component {
    onDropdownChange(event) {
        const conf = JSON.parse(JSON.stringify(this.props.conf));

        if(event.element.id === "title-family") {
            conf.title.fontFamily = event.itemData.property
        }
        else if(event.element.id === "title-size") {
            conf.title.fontSize = event.itemData.property
        }
        else if(event.element.id === "subtitle-family") {
            conf.subtitle.fontFamily = event.itemData.property
        }
        else if(event.element.id === "subtitle-size") {
            conf.subtitle.fontSize = event.itemData.property
        }

        this.props.changeConf(conf)
    }

    onBackgroundColorPickerChange(event) {
        const conf = JSON.parse(JSON.stringify(this.props.conf));
        
        conf.background.backgroundColor = event.value

        this.props.changeConf(conf)
    }

    onTitleColorPickerChange(event) {
        const conf = JSON.parse(JSON.stringify(this.props.conf));
            
        conf.title.color = event.value

        this.props.changeConf(conf)
    }

    onSubtitleColorPickerChange(event) {
        const conf = JSON.parse(JSON.stringify(this.props.conf));
            
        conf.subtitle.color = event.value

        this.props.changeConf(conf)
    }

    onInputChecked(event) {
        const conf = JSON.parse(JSON.stringify(this.props.conf));

        switch(event.target.value) {
            case "bold":
                if(event.target.id === "title-bold") {
                    conf.title.bold = event.target.checked ? "bold" : ""
                }
                else {
                    conf.subtitle.bold = event.target.checked ? "bold" : ""
                }
                break
            case "italic":
                if(event.target.id === "title-italic") {
                    conf.title.italic = event.target.checked ? "italic" : ""
                }
                else {
                    conf.subtitle.italic = event.target.checked ? "italic" : ""
                }
                break
            case "underline":
                if(event.target.id === "title-underline") {
                    conf.title.underline = event.target.checked ? "underline" : ""
                }
                else {
                    conf.subtitle.underline = event.target.checked ? "underline" : ""
                }
                break
            case "line-through":
                if(event.target.id === "title-line-through") {
                    conf.title.lineThrough = event.target.checked ? "line-through" : ""
                }
                else {
                    conf.subtitle.lineThrough = event.target.checked ? "line-through" : ""
                }
                break
            default:
                break
        }

        this.props.changeConf(conf)
    }

    render() {
        const t = (value) => i18next.t(value)

        const { 
            conf,
            editMode,
            disabled
        } = this.props;

        return (
            <div className="col mw-config-col mt-2">
                <div className="row mb-4">
                    <div className="col">
                        {editMode ?
                            <Button 
                                cssClass={"save-modification-button text-capitalize bg-green c-white font-size-19 font-size-bold py-2 px-5 rounded-m"}
                                value={<span><img alt="" src={whiteCheck}/>{t("promo.multimedia_save")}</span>}
                                onClick={this.props.saveMultimediaPromo}
                            />
                        :
                            <Button 
                                cssClass={"modifier-button text-capitalize bg-white c-prussia font-size-19 font-size-bold py-2 px-4 rounded-m"}
                                value={<span>{t("promo.multimedia_edit")}</span>}
                                onClick={this.props.changeMode}
                            />
                        }
                    </div>
                </div>
                <Background
                    onBackgroundColorPickerChange={this.onBackgroundColorPickerChange.bind(this)}
                    backgroundColor={conf.background.backgroundColor}
                    disabled={disabled}
                />
                <Text 
                    typeText="title"
                    onDropdownChange={this.onDropdownChange.bind(this)}
                    onBackgroundColorPickerChange={this.onBackgroundColorPickerChange.bind(this)}
                    onTitleColorPickerChange={this.onTitleColorPickerChange.bind(this)}
                    onInputChecked={this.onInputChecked.bind(this)}
                    conf={conf}
                    disabled={disabled}
                />
                <Text 
                    typeText="subtitle"
                    onDropdownChange={this.onDropdownChange.bind(this)}
                    onBackgroundColorPickerChange={this.onBackgroundColorPickerChange.bind(this)}
                    onSubtitleColorPickerChange={this.onSubtitleColorPickerChange.bind(this)}
                    onInputChecked={this.onInputChecked.bind(this)}
                    conf={conf}
                    disabled={disabled}
                />
            </div>
        )
    }
}

export default Config