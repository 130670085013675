import React, { Component } from 'react';
import { Internationalization } from '@syncfusion/ej2-base';
import "./event-schedule.css"
import videoPreview from "../../../../images/video_small@2x.png"
import textPreview from "../../../../images/text_small@2x.png"
import crownBlue from "../../../../images/crown_blue@3x.png"
import pinOrange from "../../../../images/pin_orange@3x.png"
import pinBlue from "../../../../images/pin_blue@3x.png"
import i18next from 'i18next';

export class EventScheduleWeekLarge extends Component {
    constructor(...args) {
        super(...args);
        
        this.data = this.props.element;
        this.instance = new Internationalization();
    }

    getDateString(value) {
        return this.instance.formatDate(value, { skeleton: 'long' });
    }

    render() {
        const t = (value) => i18next.t(value)

        const bgPromo = this.data.visibility === "COMPANY_USER" ? " bg-prussia" : " bg-carotina"
        const cPromo = this.data.visibility === "COMPANY_USER" ? " c-prussia" : " c-carotina"
        const iconTime = this.data.visibility === "COMPANY_USER" ? " custom-time-prussia" : " custom-time-carotina"
        const iconDate = this.data.visibility === "COMPANY_USER" ? " custom-date-prussia" : " custom-date-carotina"
        
        let image = null
        if(this.data.picture_landscape !== "") {
            image = this.data.picture_landscape
        }
        else if(this.data.picture_portrait !== "") {
            image = this.data.picture_portrait
        }
        else if(this.data.video_landscape !== "") {
            image = videoPreview
        }
        else if(this.data.video_portrait !== "") {
            image = videoPreview
        }
        else {
            image = textPreview
        }

        return (
        <div className="row m-0 w-100 h-100">
            <div className={"e-appointment-week-field separator rounded-l align-items-center justify-content-center h-100 d-sm-flex"+bgPromo}>
            </div>
            <div className="e-appointment-week-field image align-items-center justify-content-center h-100 d-none d-sm-flex pl-3 pr-3">
                <img className="e-appointment-image rounded-l" src={image} alt=""/>
            </div>
            <div className="col p-0 e-appointment-week-field d-flex align-items-center justify-content-center width-calc-container">
                <div className="col-12 h-100">
                    <div className="row h-50 d-flex align-items-center">
                        <div className="col-6 text-truncate">
                            {this.data.visibility === "COMPANY_USER" ? <img alt="" style={{width: "14px", height: "12px", marginBottom:"3px"}} className="mr-1" src={crownBlue}/> : null}
                            <span className="font-weight-bold font-size-20 c-opale">{this.data.name}</span>
                        </div>
                        <div className="col p-0 d-flex justify-content-end">
                            <div className="col-auto p-0 d-flex">
                                <div className="col-auto p-0 pr-1">
                                    <img alt="" style={{width: "12px"}} src={this.data.visibility === "COMPANY_USER" ? pinBlue : pinOrange}/>
                                </div>
                                <div className="col">
                                    <div className="row">
                                        <span className="font-weight-regular font-size-14 c-opale">{this.data.restaurants[0].name}</span>
                                    </div>
                                    <div className="row">
                                        <span className="font-weight-bold font-size-14 c-opale">{this.data.restaurants[0].site_name}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <span className={"font-weight-bold font-size-18 c-white custom-badge ml-2"+bgPromo}>{this.data.restaurants.length}+</span>
                            </div>
                        </div>
                    </div>
                    <div className="row h-25">
                        <div className="col-xl-3">
                            <span className={"d-block font-weight-bold font-size-14 opacity-5"+cPromo}>{t("promo.start_date")}</span>
                            <span className={"d-block font-weight-regular font-size-14 c-opale"+iconDate}>{this.getDateString(this.data.starts_at)}</span>
                        </div>
                        <div className="col-xl-3">
                            <span className={"d-block font-weight-bold font-size-14 opacity-5"+cPromo}>{t("promo.end_date")}</span>
                            <span className={"d-block font-weight-regular font-size-14 c-opale"+iconDate}>{this.getDateString(this.data.ends_at)}</span>
                        </div>
                    </div>
                    <div className="row h-25 d-flex align-items-center">
                        <div className="col-6 col-sm-4 offset-6 offset-sm-8">
                            <span className={"float-right font-weight-regular font-size-14 c-opale"+iconTime}>from {this.data.starts_time_at} to {this.data.ends_time_at}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default EventScheduleWeekLarge