import i18next from "i18next";
import { Component } from "react";
import userRealtime from "../../../images/real_time_people.svg"

class UsersRealtime extends Component {
    render() {
        const t = (value) => i18next.t(value)

        const { usersRealtime } = this.props;

        return (
            <div className="row m-0 h-100">
                <div className="col-auto d-flex flex-column justify-content-center">
                    <img alt="" className="image-user-chart" src={userRealtime}/>
                </div>
                <div className="col d-flex flex-column justify-content-center">
                    <p className="font-weight-bold font-size-29 c-prussia mb-2">
                        {t("fluidity_company.users_realtime")}
                    </p>
                    {usersRealtime === -1 ? 
                    <p className="font-weight-bold font-size-38 c-opale mb-0">
                        {t("fluidity_company.no_fluidity")}
                    </p>
                    :
                    <p className="font-weight-bold font-size-55 c-opale mb-0">
                        {usersRealtime}
                    </p>}
                </div>
            </div>
        )
    }
}

export default UsersRealtime