import { Component } from "react";
import SiteComparison from "./comparison/SiteComparison";
import userRealtime from "../../../images/real_time_people.svg"
import userTotal from "../../../images/total_people.svg"
import ominoWhite from "../../../images/omino_white@3x.png"
import i18next from "i18next";


class FluidityRestaurant extends Component {
    constructor(...args) {
        super(...args)

        this.state = {
            loaded: false,
            fluiditySiteRealtime: {},
            fluiditySiteTotal: {},
            bgColor: ""
        }
    }

    componentDidMount() {
        this.setState({
            fluiditySiteRealtime: this.props.fluiditySiteRealtime,
            fluiditySiteTotal: this.props.fluiditySiteTotal,
            bgColor: this.props.bgColor,
            loaded: true
        })
    }

    render() {
        const t = (value) => i18next.t(value)

        const { fluiditySiteRealtime, fluiditySiteTotal } = this.props;
        
        if(!this.state.loaded) {
            return <div>loading</div>
        }
        else {
            return (
                <>
                    {fluiditySiteRealtime.site.restaurants ? fluiditySiteRealtime.site.restaurants.map((restaurant, index) => {
                        return (
                            <div key={index} className="row w-100 bg-white shadow rounded-m p-3 m-0 mb-3">
                                <div className="col-12">
                                    <p className="font-weight-bold font-size-40 c-opale">{restaurant.name}</p>
                                </div>
                                <div className="col-4">
                                    <div className="row">
                                        <div className="col-12 d-flex border-bottom">
                                            <div className="col-auto">
                                                <img alt="" className="site-fluidity-image" src={userRealtime}/>
                                            </div>
                                            <div className="col">
                                                <p className={this.state.bgColor === " bg-prussia" ? "font-weight-bold font-size-29 c-prussia" : "font-weight-bold font-size-29 c-celestino"}>{t("fluidity_site.users_realtime")}</p>
                                                    {restaurant.users_realtime === -1 ?
                                                    <p className="font-weight-bold font-size-50 c-opale m-0 text-nowrap"> 
                                                        {t("fluidity_site.no_fluidity")} 
                                                    </p>:
                                                    <p className="font-weight-bold font-size-72 c-opale m-0 text-nowrap"> 
                                                        {restaurant.users_realtime}
                                                    </p>}
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex mt-3">
                                            <div className="col-auto">
                                                <img alt="" className="site-fluidity-image" src={userTotal}/>
                                            </div>
                                            <div className="col">
                                                <p className={this.state.bgColor === " bg-prussia" ? "font-weight-bold font-size-29 c-prussia" : "font-weight-bold font-size-29 c-celestino"}>{t("fluidity_site.users_total")}</p>
                                                <p className="font-weight-bold font-size-72 c-opale text-nowrap">
                                                    {restaurant.users_realtime === -1 ? 
                                                    <p className="font-weight-bold font-size-50 c-opale m-0 text-nowrap"> 
                                                    {t("fluidity_site.no_fluidity")} 
                                                    </p>:
                                                    <p className="font-weight-bold font-size-72 c-opale m-0 text-nowrap">
                                                        {fluiditySiteTotal.site.restaurants[index].users_total}
                                                    </p>}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {restaurant.users_realtime === -1 ?
                                    null
                                :
                                    <>
                                    <div className="col-4 d-flex">
                                        {restaurant.regions.map((region, key) => {
                                            return (
                                                <div key={key} className="col-6 pl-4 py-2" style={{visibility: region.users_realtime === -1 ? "hidden" : "visible"}}>
                                                    <div className={"shadow rounded-m h-100 d-flex flex-column justify-content-center px-3"+this.state.bgColor} style={{maxWidth: "200px"}}>
                                                        <div className="row justify-content-center">
                                                            <p className="font-weight-regular font-size-33 c-white text-center">{region.name}</p>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <p className="font-weight-bold font-size-55 c-white text-center">{region.users_realtime}</p>
                                                            <img alt="" style={{top: "12px", height: "30px"}} className="omino" src={ominoWhite}/>
                                                        </div>
                                                    </div>
                                                </div> 
                                            )
                                        })}
                                    </div>
                                    {fluiditySiteTotal.site.restaurants[index].comparison ?
                                        <div className="col-4">
                                            <SiteComparison orientation="twoForTwo" comparison={fluiditySiteTotal.site.restaurants[index].comparison}/>
                                        </div>  
                                    :
                                            null
                                    }       
                                    </>
                                }                     
                            </div>
                        )
                    }) : null}
                </>
            )
        }
    }
}

export default FluidityRestaurant

/* <img alt="" className="image-user-chart" src={userTotal}/> */