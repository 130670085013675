import React, { Component } from "react";
import { TabComponent } from "@syncfusion/ej2-react-navigations";
import "./form-promo-tab.css"

class Tabs extends Component {
    render() {
        //const { t } = this.props;

        const { cssClass, conf } = this.props;

        return (
            <TabComponent cssClass={cssClass}>
                <div className="e-tab-header mb-3">
                    {conf.map((tab, key) => {
                        return (
                            <div key={key}>{tab.text}</div>
                        )
                    })}
                </div>
                <div className="e-content">
                    {conf.map((tab, key) => {
                        return (
                            <div key={key}>{tab.content}</div>
                        )
                    })}                        
                </div>
            </TabComponent>
        );
    }
}

export default Tabs;