import React, { Component } from 'react';
import { Internationalization } from '@syncfusion/ej2-base';
import "./quick-info.css"
import { deletePromotion } from '../../../../api/fetch';
import { DialogUtility } from '@syncfusion/ej2-popups';
import videoPreview from "../../../../images/video_big@2x.png"
import textPreview from "../../../../images/Text_big@2x.png"
import crownBlue from "../../../../images/crown_blue@3x.png"
import pinOrange from "../../../../images/pin_orange@3x.png"
import pinBlue from "../../../../images/pin_blue@3x.png"
import i18next from 'i18next';

class QuickInfo extends Component {
    constructor(...args) {
        super(...args);
        
        this.state = {
            heightTriggerContainer: "0px"
        }

        this.data = this.props.element;
        this.instance = new Internationalization();
    }

    componentDidMount() {
        if(document.getElementById('e-update-promo'))
            document.getElementById('e-update-promo').onclick = () => {
                this.editPromo();
            };

        if(document.getElementById('e-delete-promo'))
            document.getElementById('e-delete-promo').onclick = () => {
                this.deletePromoConfirm();
            };

        window.addEventListener('resize', this.maxHeightCalc.bind(this));
        setTimeout(this.maxHeightCalc.bind(this), 300);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.maxHeightCalc.bind(this));
    }

    getDateString(value) {
        return this.instance.formatDate(value, { skeleton: 'long' });
    }

    deletePromoConfirm() {
        const t = (value) => i18next.t(value)

        this.ConfirmDelete = DialogUtility.confirm({
            animationSettings: { effect: 'Zoom' },
            cancelButton: { text: t("promotions_planning.promo_delete_popup_no"), cssClass: "bg-white rounded-s font-weight-bold font-size-16"},
            closeOnEscape: true,
            content: t("promotions_planning.promo_delete_popup_subtitle"),
            okButton: { text: t("promotions_planning.promo_delete_popup_yes"), click: this.deletePromo.bind(this), cssClass: "c-white bg-red rounded-s font-weight-bold font-size-16" },
            showCloseIcon: false,
            title: t("promotions_planning.promo_delete_popup_title"),
            cssClass: "delete-promo-popup m-0 rounded-m c-opale bg-alert-red font-size-16"
        });
    }
    async deletePromo() {
        const result = await deletePromotion(this.data.id)
        if(result === "deleted") {
            this.ConfirmDelete.hide()
            this.props.closePopup()
            this.props.refreshPromotions()
        }
    }

    editPromo() {
        this.props.history.push({
            pathname: "/promo/edit",
            state: { promoID: this.data.id }
        })
    }

    verifyDate(start, end) {
        const today = new Date()
        start = new Date(start)
        end = new Date(end)

        return start<=today && today<=end
    }

    verifyTime(start, end) {
        let now = new Date()
        
        const minutes = now.getMinutes()
        const hours = now.getHours() < 10 ? "0"+now.getHours() : now.getHours()

        start = start.split(":")[0]+start.split(":")[1]
        now = hours+""+minutes
        end = end.split(":")[0]+end.split(":")[1]

        return start<now && now<end
    }

    verifyTrigger(restaurants) {
        let isActive = true
        restaurants.forEach(restaurant => {
            if(restaurant.region.id !== 0) {
                if(!restaurant.region.level_reached) {
                    isActive = false
                }
            }
        })

        return isActive
    }

    maxHeightCalc() {
        const id = this.data.id
        const index = document.querySelectorAll("[id='promo-"+id+"']").length-1
        if(index >= 0) {
            const detailHeight = document.querySelectorAll("#promo-"+id+" .detail-promo")[index].offsetHeight
            const nameHeight = document.querySelectorAll("#promo-"+id+" .detail-promo .promo-name")[index].offsetHeight
            const infoHeight = document.querySelectorAll("#promo-"+id+" .detail-promo .promo-info")[index].offsetHeight
            const triggerLabelHeight = document.querySelectorAll("#promo-"+id+" .detail-promo .promo-trigger-label")[index].offsetHeight
            const triggerContainerHeight = detailHeight - nameHeight - infoHeight - triggerLabelHeight - 68

            this.setState({
                heightTriggerContainer: triggerContainerHeight+"px"
            })
        }
    }

    render() {
        const t = (value) => i18next.t(value)

        const cPromo = this.data.visibility === "COMPANY_USER" ? " c-prussia" : " c-carotina"
        const iconTime = this.data.visibility === "COMPANY_USER" ? " custom-time-prussia" : " custom-time-carotina"
        const iconDate = this.data.visibility === "COMPANY_USER" ? " custom-date-prussia" : " custom-date-carotina"

        let image = null
        if(this.data.picture_landscape !== "") {
            image = this.data.picture_landscape
        }
        else if(this.data.picture_portrait !== "") {
            image = this.data.picture_portrait
        }
        else if(this.data.video_landscape !== "") {
            image = videoPreview
        }
        else if(this.data.video_portrait !== "") {
            image = videoPreview
        }
        else {
            image = textPreview
        }

        let isPromoActive = true
        isPromoActive = !isPromoActive ? false : this.verifyDate(this.data.starts_at, this.data.ends_at)
        isPromoActive = !isPromoActive ? false : this.verifyTime(this.data.starts_time_at, this.data.ends_time_at)
        isPromoActive = !isPromoActive ? false : this.verifyTrigger(this.data.restaurants)
        
        return (
        <div className="container-fluid h-100 d-flex flex-column" id={"promo-"+this.data.id}>
            <div className="row header">
                <div className="col-12 d-flex">
                    {this.data.visibility === "COMPANY_USER" ? <img alt="" style={{width: "30px", height: "25px"}} className="mr-2" src={crownBlue}/> : null}
                    <p className={"opacity-5 font-weight-bold font-size-34 mb-1"+cPromo}>{t("promotions_planning.quick_info_title")}</p>
                </div>
                <div className="col-12">
                    {isPromoActive ?
                        <p className="c-opale font-weight-bold font-size-25 mb-2">{t("promotions_planning.promo_enabled")}</p>
                    :
                        <p className="c-opale opacity-5 font-weight-bold font-size-25 mb-2">{t("promotions_planning.promo_disabled")}</p>
                    }
                </div>
            </div>
            <div className="d-flex flex-column p-4 border rounded-m content detail-promo">
                <div className="row promo-name" style={{flex: "0 1"}}>
                    <div className="col-10">
                        <p className="c-opale font-size-25 font-weight-medium text-truncate">{this.data.name}</p>
                    </div>
                    {this.data.visibility === "COMPANY_USER" ?
                        <>
                            <div className="col-1 pr-1">
                                <span id="e-delete-promo" className="e-icons e-delete-promo"/>
                            </div>
                            <div className="col-1 pl-1">
                                <span id="e-update-promo" className="e-icons e-update-promo opacity-5 c-opale"/>
                            </div>
                        </>
                    :
                        null
                    }
                </div>
                <div className="row mb-4 promo-info" style={{flex: "0 1"}}>
                    <div className="col-xl-auto">
                        <img className="image rounded-l" src={image} alt=""/>
                    </div>
                    <div className="col-xl d-flex flex-wrap align-items-center py-4">
                        <div className="row w-100">
                            <div className="col-lg-6 mb-3">
                                <p className={"opacity-5 font-size-16 font-weight-bold mb-1"+cPromo}>{t("promotions_planning.start_date")}</p>
                                <p className={"c-opale font-size-14 font-weight-bold m-0"+iconDate}>
                                    {this.getDateString(this.data.starts_at)}
                                </p>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <p className={"opacity-5 font-size-16 font-weight-bold mb-1"+cPromo}>{t("promotions_planning.end_date")}</p>
                                <p className={"c-opale font-size-14 font-weight-bold m-0"+iconDate}>
                                    {this.getDateString(this.data.ends_at)}
                                </p>
                            </div>
                        </div>
                        <div className="row w-100">
                            <div className="col-lg-6">
                                <p className={"opacity-5 font-size-16 font-weight-bold mb-1"+cPromo}>{t("promotions_planning.start_time")}</p>
                                <p className={"c-opale font-size-14 font-weight-bold m-0 mb-2"+iconTime}>
                                    {this.data.starts_time_at}
                                </p>
                            </div>
                            <div className="col-lg-6">
                                <p className={"opacity-5 font-size-16 font-weight-bold mb-1"+cPromo}>{t("promotions_planning.end_time")}</p>
                                <p className={"c-opale font-size-14 font-weight-bold m-0 mb-2"+iconTime}>
                                    {this.data.ends_time_at}
                                </p>
                            </div>
                        </div>
                        <div className="row w-100">
                            <div className="col-12">
                                <p className="c-opale font-size-14 font-weight-bold m-0">
                                    {this.data.description}
                                </p> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row promo-trigger-label" style={{flex: "0 1"}}>
                    <div className="col-12">
                        <p className="c-opale opacity-7 font-size-19 font-weight-bold">
                            {t("promotions_planning.promo_restaurant_display")}
                        </p>
                    </div>
                </div>
                <div className="row restaurant-container w-100 m-0 p-0 overflow-auto promo-trigger" style={{maxHeight: this.state.heightTriggerContainer}}>
                    {this.data.restaurants.map((restaurant, index) => {
                        return (
                            <div key={index} className="col-xl-4 row m-0 px-1">
                                <div className="col-12 p-0 pl-1 text-nowrap d-flex overflow-hidden">
                                    <div className="col-auto p-0">
                                        <img alt="" style={{width: "15px", height: "20px", marginRight: "5px"}} src={this.data.visibility === "COMPANY_USER" ? pinBlue : pinOrange}/>
                                    </div>
                                    <div className="col mb-1 p-0 d-flex flex-wrap">
                                        <p className="col-auto p-0 d-inline c-opale font-size-16 font-weight-regular mb-0">
                                            {restaurant.name+","}&nbsp;
                                        </p>
                                        <p className="col-auto p-0 d-inline c-opale font-size-16 font-weight-bold mb-0 ">
                                            {restaurant.site_name}
                                        </p>
                                    </div>
                                </div>
                                {restaurant.region.id !== 0 ?
                                    <div key={index} className="col-12" style={{padding: "0", paddingLeft: "calc(20px + 0.25rem)"}}>
                                        <p className={"opacity-5 font-size-16 font-weight-bold mb-1"+cPromo}>
                                            {t("promotions_planning.fluidity_trigger_title")}
                                        </p>
                                        <p className={"font-size-16 font-weight-bold trigger-active mb-1"+cPromo}>
                                            {t("promotions_planning.trigger_active")}&nbsp;{restaurant.region.capacity_level+"%"}
                                        </p>
                                        <p className="c-opale opacity-5 font-size-12 font-weight-regular">
                                            {t("promotions_planning.on_zone")}&nbsp;{restaurant.region.name+'"'}
                                        </p>
                                    </div>
                                    :
                                    <div key={index} className="col-12" style={{padding: "0", paddingLeft: "calc(20px + 0.25rem)"}}>
                                        <p className={"opacity-5 font-size-16 font-weight-bold mb-1"+cPromo}>
                                            {t("promotions_planning.fluidity_trigger_title")}
                                        </p>
                                        <p className="c-opale opacity-5 fonct-size-16 font-weight-bold">
                                            {t("promotions_planning.trigger_inactive")}
                                        </p>
                                    </div>
                                }
                            </div>
                        )
                    })}
                    
                </div>
            </div>
            
        </div>
        );
    }
}

export default QuickInfo