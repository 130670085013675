import { Component } from "react";
import Switch from "../../wrapper/switch/Switch";
import DropDownList from "../../wrapper/dropDown/DropDownList";
import NumericTextBox from "../../wrapper/numericTextBox.js/NumericTextBox";
import i18next from "i18next";

class RestaurantTriggerList extends Component {
    constructor(...args) {
        super(...args)

        this.state = {
            triggerActive: false,
            triggers: {},
            region: null,
            trigger: null
        }
    }

    componentDidMount() {
        const { promo, restaurant, setTrigger } = this.props;

        promo.restaurants.forEach(restaurantPromo => {
            if(parseInt(restaurantPromo.place_id, 10) === restaurant.id && restaurantPromo.region_id) {
                this.setState({
                    region: restaurantPromo.region_id,
                    trigger: restaurantPromo.capacity_level,
                    triggerActive: true
                })

                if(restaurantPromo.region_id !== 0)
                    setTrigger({restaurantID: restaurant.id, region: restaurantPromo.region_id, trigger: restaurantPromo.capacity_level, level_reached: restaurantPromo.level_reached})
            }
        })

        this.setState({loaded: true})
    }

    onChangeCheck() {
        this.setState({
            triggerActive: !this.state.triggerActive
        })
        if(!this.state.triggerActive) {
            const { restaurant, unsetTrigger } = this.props;

            unsetTrigger(restaurant.id)
            this.setState({
                region: null,
                trigger: null
            })
        }
    }

    onDropdownZoneChange(event) {
        const { restaurant, setTrigger } = this.props;

        this.setState({
            region: event.itemData.id,
            trigger: 0
        });     

        setTrigger({restaurantID: restaurant.id, region: event.itemData.id, trigger: 1})
    }

    onTextBoxPercentageChange(event) {
        const { restaurant, setTrigger } = this.props;

        if(this.state.region !== 0)
            setTrigger({restaurantID: restaurant.id, region: this.state.region, trigger: event.value})
    }

    render() {
        const t = (value) => i18next.t(value)

        const { restaurant } = this.props;
        const { triggerActive, region, trigger} = this.state;

        const disabled = triggerActive?"":" disabled"

        return (
            <div id={"restaurant-trigger-"+restaurant.id} className="row mx-3 mb-3 w-100 d-none">
                <div className="col-12 row mb-2">
                    <div className="col-auto d-flex align-items-center">
                        <Switch cssClass="custom-switch-component" checked={triggerActive} change={this.onChangeCheck.bind(this)}/>
                    </div>
                    <div className="col p-0">
                        <p className="font-weight-bold font-size-27 c-opale m-0">
                            {restaurant.name}
                        </p>
                    </div>
                </div>
                {triggerActive ? 
                    <div className="col-2 pr-5">
                        <div className="col-12 p-0">
                            <p className="font-weight-bold font-size-18 c-prussia opacity-5 m-0 mb-2">
                                {t("promo.fluidity_trigger_pick")}
                            </p>
                        </div>
                        <div className="col-12 p-0">
                            <DropDownList
                                id={"restaurant-trigger-zone-"+restaurant.id}
                                fields={{text:"name", value:"id"}}
                                dataSource={restaurant.regions}
                                cssClass={"promo-text-dropdown-zone"+disabled}
                                change={this.onDropdownZoneChange.bind(this)}
                                placeholder={t("promo.fluidity_trigger_pick_placeholder")}
                                value={region ? region : null}
                                
                            /> 
                        </div>
                    </div>
                : 
                    null
                }
                {triggerActive ? 
                    <div className="col-3 row">
                        <div className="col-12 p-0">
                            <p className="font-weight-bold font-size-18 c-prussia opacity-5 m-0 mb-2">
                                {t("promo.fluidity_trigger_value")}
                            </p>
                        </div>
                        <div className="col-4 p-0">
                            <NumericTextBox
                                id={"restaurant-trigger-percentage-"+restaurant.id}
                                min={1}
                                format="###"
                                max={100}
                                cssClass={"promo-text-numeric-box"+disabled}
                                change={this.onTextBoxPercentageChange.bind(this)}
                                value={trigger === 0 ? 1 : trigger}
                                showSpinButton={false}
                            />
                        </div>
                    </div>
                : 
                    null
                }
            </div>
        )
    }
}

export default RestaurantTriggerList