import { DashboardLayoutComponent } from "@syncfusion/ej2-react-layouts"
import React, { Component } from "react";
import "./fluidity-dashboard.css"
import UsersRealtime from "./cards/UsersRealtime";
import UsersTotal from "./cards/UsersTotal";
import BarChart from "../common/barChart/BarChart";
import { fetchFluidityCompanyRealtime, fetchFluidityCompanyTotal } from "../../api/fetch";
import Button from "../wrapper/button/Button";
import CompanyComparison from "./comparison/CompanyComparison";
import ominoWhite from "../../images/omino_white@3x.png"
import i18next from "i18next";
import { getCurrentDateWithTZ } from "../../utility/tz";


class FluidityDashboard extends Component {
    constructor(...args) {
        super(...args);

        this.state = {
            loaded: false,
            fluidityRealtime: {},
            fluidityTotal: {}
        }
    }

    async componentDidMount() {
        const currentdate = getCurrentDateWithTZ()
        const fluidityRealtime = await fetchFluidityCompanyRealtime(currentdate)
        const fluidityTotal = await fetchFluidityCompanyTotal(currentdate)

        this.setState({
            fluidityTotal: fluidityTotal,
            fluidityRealtime: fluidityRealtime,
            loaded: true
        })
    }

    isNotUneven(number) {
        if (isNaN(number) === false) {
            return (number %2 === 1 ?  true : false);
        }
        else {
            return null;
        }
    }

    gotoSiteFluidity(siteID, siteName, color) {
        this.props.history.push({
            pathname: "/fluidity/site",
            state: { siteID: siteID, siteName: siteName, bgColor: color }
        })
    }

    render() {
        const t = (value) => i18next.t(value)

        const { fluidityRealtime, fluidityTotal } = this.state;

        if(!this.state.loaded) {
            return <div>loading</div>
        }
        else {
            return (
                <div>
                    <div className="col-12 p-0">
                        <p className="font-weight-regular font-size-18 c-opale">{t("fluidity_company.lunch_dinner")}</p>
                    </div>
                    <div className="control-section">
                        <DashboardLayoutComponent allowDragging={false} id='fluidity-dashboard' cellSpacing={[20, 20]} cellAspectRatio={100 / 70} columns={12}>
                            <div className="e-panel rounded-l" data-row="0" data-col="0" data-sizex="4" data-sizey="2">
                                <div className="e-panel-container">
                                    <div className="e-panel-content">
                                        <UsersRealtime usersRealtime={fluidityRealtime.company.users_realtime}/>
                                    </div>
                                </div>
                            </div>
                            <div className="e-panel rounded-l" data-row="2" data-col="0" data-sizex="4" data-sizey="2">
                                <div className="e-panel-container">
                                    <div className="e-panel-content">
                                        <UsersTotal usersRealtime={fluidityRealtime.company.users_realtime} usersTotal={fluidityTotal.company.users_total}/>
                                    </div>
                                </div>
                            </div>
                            {fluidityTotal.company.sites.length > 3 ?
                            <>
                                <div className="e-panel rounded-l" data-row="0" data-col="4" data-sizex="8" data-sizey="4">
                                    <div className="e-panel-container">
                                        <div className="e-panel-content">
                                            <BarChart sites={fluidityTotal.company.sites} number={" "+fluidityTotal.company.users_total}/>
                                        </div>
                                    </div>
                                </div>
                                {fluidityTotal.company.comparison && fluidityRealtime.company.users_realtime !== -1 ?
                                    <div className="e-panel rounded-l" data-row="4" data-col="0" data-sizex="12" data-sizey="3">
                                        <div className="e-panel-container">
                                            <div className="e-panel-content">
                                                <CompanyComparison orientation="horizontal" comparison={fluidityTotal.company.comparison}/>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    null
                                }
                            </>
                            :
                            <>
                                <div className="e-panel rounded-l" data-row="0" data-col="4" data-sizex="4" data-sizey="4">
                                    <div className="e-panel-container">
                                        <div className="e-panel-content">
                                            <BarChart sites={fluidityTotal.company.sites} number={" "+fluidityTotal.company.users_total}/>
                                        </div>
                                    </div>
                                </div>
                                {fluidityTotal.company.comparison && fluidityRealtime.company.users_realtime !== -1 ?
                                    <div className="e-panel rounded-l" data-row="0" data-col="8" data-sizex="4" data-sizey="9">
                                        <div className="e-panel-container">
                                            <div className="e-panel-content">
                                                <CompanyComparison orientation="vertical" comparison={fluidityTotal.company.comparison}/>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    null
                                }
                            </>
                            }
                            <div className="e-panel rounded-l" data-row="7" data-col="0" data-sizex={fluidityTotal.company.sites.length>3?"12":"8"} data-sizey="5">
                                <div className="e-panel-container">
                                    <div className="e-panel-content p-4">
                                        <div className="row mb-4">
                                            <div className="col-12">
                                                <p className="font-weight-bold font-size-33 c-opale mb-2">
                                                    {t("fluidity_company.sites_title")}
                                                </p>
                                            </div>
                                            <div className="col-12">
                                                <p className="font-weight-regular font-size-20 c-opale opacity-5 m-0">
                                                    {t("fluidity_company.sites_subtitle")}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row flex-nowrap overflow-auto">
                                            {fluidityRealtime.company.sites.map((site, index) => {
                                                return (
                                                    <div className="col-auto pb-2" key={index} style={{height: "280px"}}>
                                                        <div className={this.isNotUneven(index) ? "e-card py-4 px-2 rounded-l bg-celestino" : "e-card py-4 px-2 rounded-l bg-prussia"} id="basic" style={{width: "200px", height: "240px"}}>
                                                            <div className="e-card-header w-100 p-0 mb-5">
                                                                <div className="e-card-header-caption">
                                                                    <div className="e-card-title font-weight-regular font-size-28 c-white text-center">{site.name}</div>
                                                                </div>
                                                            </div>
                                                            {site.users_realtime === -1 ? 
                                                                <div className="mb-5 text-center font-weight-regular font-size-34 c-white">
                                                                {t("fluidity_company.no_fluidity")}
                                                                </div>
                                                            : 
                                                                <div className="mb-5 text-center font-weight-regular font-size-50 c-white">
                                                                {site.users_realtime} 
                                                                <img alt="" className="omino" src={ominoWhite}/>
                                                                </div>
                                                            }
                                                            {site.users_realtime === -1 ? null
                                                            :
                                                                <Button
                                                                    cssClass="font-weight-bold font-size-22 bg-white c-prussia rounded-s w-auto px-3 py-2 mx-auto mb-2"
                                                                    value={t("fluidity_company.sites_info")}
                                                                    onClick={this.gotoSiteFluidity.bind(this, site.id, site.name, this.isNotUneven(index)?" bg-celestino":" bg-prussia")}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DashboardLayoutComponent>
                    </div>
                </div>
            )
        }
    }
}

export default FluidityDashboard