import { fetchApi } from "./model";

function login(token) {
    return fetchApi("/login", { method: "POST", headers: { 'Authorization': `Bearer ${token}` } });
}
function fetchPromotions(start, end) {
    return fetchApi(`/promotions/read?starts_at=${start}&ends_at=${end}`, { method: "GET" });
}
function fetchDataForAddPromotion() {
    return fetchApi(`/promotion/data/read`, { method: "GET" });
}
function createPromotion(data) {
    return fetchApi(`/promotion/create`, { method: "POST", headers: { 'Content-Type': 'application/json; charset=UTF-8' }, body: JSON.stringify(data) });
}
function updatePromotion(data) {
    return fetchApi(`/promotion/update`, { method: "PUT", headers: { 'Content-Type': 'application/json; charset=UTF-8' }, body: JSON.stringify(data) });
}
function fetchPromotion(id) {
    return fetchApi(`/promotion/read?edito_item_id=${id}`, { method: "GET" });
}
function deletePromotion(id) {
    return fetchApi(`/promotion/delete?edito_item_id=${id}`, { method: "DELETE" });
}
function fetchFluidityCompanyRealtime(frontdate) {
    return fetchApi(`/fluidity/company/realtime/read?date=${frontdate}`, { method: "GET" });
}
function fetchFluidityCompanyTotal(frontdate) {
    return fetchApi(`/fluidity/company/total/read?date=${frontdate}`, { method: "GET" });
}
function fetchFluiditySiteRealtime(id, name, frontdate) {
    return fetchApi(`/fluidity/site/realtime/read?site_id=${id}&site_name=${name}&date=${frontdate}`, { method: "GET" });
}
function fetchFluiditySiteTotal(id, name, frontdate) {
    return fetchApi(`/fluidity/site/total/read?site_id=${id}&site_name=${name}&date=${frontdate}`, { method: "GET" });
}
function upload(data) {
    return fetchApi(`/upload`, { method: "POST", body: data });
}
function setLanguage(data) {
    return fetchApi(`/language/update`, { method: "POST", body: data });
}
function logout() {
    return fetchApi(`/logout`, { method: "GET" });
}

export {
    login,
    fetchPromotions,
    fetchDataForAddPromotion,
    createPromotion,
    updatePromotion,
    fetchPromotion,
    deletePromotion,
    fetchFluidityCompanyRealtime,
    fetchFluidityCompanyTotal,
    fetchFluiditySiteRealtime,
    fetchFluiditySiteTotal,
    upload,
    setLanguage,
    logout
}