import React, { Component } from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';

export class Dialog extends Component {
    constructor(...args) {
        super(...args);

        this.dialogClose = () => {
            this.setState({ hideDialog: false });
        };

        this.state = {
            hideDialog: true
        };

        
    }

    render() {
        const { 
            showCloseIcon,
            cssClass,
            isModal,
            content
        } = this.props;

        return (
        <DialogComponent
            isModal={isModal}
            visible={this.state.hideDialog}
            close={this.dialogClose}
            cssClass={cssClass}
            showCloseIcon={showCloseIcon}
        >
            {React.Children.map(content, child =>React.cloneElement(child, { closePopup: this.dialogClose }))}
        </DialogComponent>
        );
    }
}

export default Dialog