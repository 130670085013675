import { Category, ChartComponent, ColumnSeries, DataLabel, Inject, SeriesCollectionDirective, SeriesDirective } from "@syncfusion/ej2-react-charts";
import i18next from "i18next";
import { Component } from "react";

class BarChart extends Component {
    constructor(...args) {
        super(...args);

        this.state = {
            refreshChart: false,
            sitesValue: []
        }

        this.primaryxAxis = { valueType: 'Category', majorGridLines: { width: 0 }, majorTickLines: { width: 0 }, lineStyle: { width: 0 }, labelStyle: { fontWeight: "bold", fontFamily: "Roboto" } };
        this.primaryyAxis = { majorTickLines: { width: 0 }, lineStyle: { width: 0 }, labelStyle: { fontWeight: "bold", fontFamily: "Roboto" } }
        this.titlestyle = { textAlignment: "Near" }
        this.palette = ["#3361E5", "#76B7FF"];
        this.pointRender = (args) => {
            let seriesColor = ["#3361E5", "#76B7FF"];
            args.fill = seriesColor[this.isNotUneven(args.point.index)?0:1];
        };
    }

    isNotUneven(number) {
        if (isNaN(number) === false) {
            return (number %2 === 1 ?  true : false);
        }
        else {
            return null;
        }
    }

    componentDidMount() {
        this.setState({
            refreshChart: true,
            sites: this.props.sites
        })
    }
    
    render() {
        const t = (value) => i18next.t(value)

        if(!this.state.refreshChart) {
            return <div>loading</div>
        }
        else{
            return (
                <div className="row h-100 m-0">
                    <div className="col-12 pl-4 d-flex align-items-center h-25">
                        <p className="font-weight-bold font-size-25 c-opale">{t("home.barchart_label")}</p>
                        <p className="font-weight-bold font-size-25 c-prussia">&nbsp;{this.props.number}</p>
                    </div>
                    <div className="col-12 h-75 pb-4">
                        <ChartComponent id='charts' 
                            primaryXAxis={this.primaryxAxis}
                            primaryYAxis={this.primaryyAxis}
                            chartArea={{ border: { width: 0 }}}
                            titleStyle={this.titlestyle}
                            pointRender={this.pointRender}
                            className="h-100 rounded-l"
                        >
                            <Inject services={[ColumnSeries, Category, DataLabel]}/>
                            <SeriesCollectionDirective>
                                <SeriesDirective 
                                    dataSource={this.state.sites} 
                                    xName='name' 
                                    yName='users_total' 
                                    type='Column' 
                                    marker={{
                                        dataLabel: {
                                            visible: true, 
                                            position: 'Top',
                                            font: {
                                                fontWeight: '600',
                                                color: '#ffffff'
                                            }
                                        },
                                    }}
                                    cornerRadius={{ 
                                        topLeft: 3, 
                                        topRight: 3 
                                    }}
                                    name="Tiger"
                                >
                                </SeriesDirective>
                            </SeriesCollectionDirective>
                        </ChartComponent>
                    </div>
                </div>
            );
        } 
    }
}

export default BarChart