import React, { Component } from "react";
import { login } from "../api/fetch";


class Login extends Component {
  async componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    
    const response = await login(searchParams.get("token"));
    
    if(response.logged === "logged in") {
      localStorage.setItem("name", response.name)
      this.props.history.push("/");
    }
    else {
      window.location.replace(process.env.REACT_APP_URL_LOGIN_CLIENT);
    }
  }

  render() {
    return (
      <div></div>
    );
  }
}

export default Login;
