import i18next from "i18next";
import { Component } from "react";
import CheckBox from "../../wrapper/checkBox/checkBox";

class Presets extends Component {
    constructor(...args) {
        super(...args)

        this.labels = []
        this.sortTags = ["All", "Welcome", "Edito", "Mobile", "Landing"]
    }

    render() {
        const t = (value) => i18next.t(value)

        const {
            cssClass,
            tags,
            refID,
            onClick,
        } = this.props;

        tags.forEach(tag => {
            if(cssClass === "check-sites ") {
                switch(tag.name) {
                    case "All":
                        this.labels[tag.name] = t("promo.preset_site_check")+"("+t("promo.tag_all")+")"
                        break
                    case "Welcome":
                        this.labels[tag.name] = t("promo.preset_site_check")+"("+t("promo.tag_welcome")+")"
                        break
                    case "Edito":
                        this.labels[tag.name] = t("promo.preset_site_check")+"("+t("promo.tag_edito")+")"
                        break
                    case "Mobile":
                        this.labels[tag.name] = t("promo.preset_site_check")+"("+t("promo.tag_mobile")+")"
                        break
                    case "Landing":
                        this.labels[tag.name] = t("promo.preset_site_check")+"("+t("promo.tag_landings")+")"
                        break
                    default:
                        break
                }
            }
            else if(cssClass === "check-restaurants ") {
                switch(tag.name) {
                    case "All":
                        this.labels[tag.name] = t("promo.preset_restaurant_check")+"("+t("promo.tag_all")+")"
                        break
                    case "Welcome":
                        this.labels[tag.name] = t("promo.preset_restaurant_check")+"("+t("promo.tag_welcome")+")"
                        break
                    case "Edito":
                        this.labels[tag.name] = t("promo.preset_restaurant_check")+"("+t("promo.tag_edito")+")"
                        break
                    case "Mobile":
                        this.labels[tag.name] = t("promo.preset_restaurant_check")+"("+t("promo.tag_mobile")+")"
                        break
                    case "Landing":
                        this.labels[tag.name] = t("promo.preset_restaurant_check")+"("+t("promo.tag_landings")+")"
                        break
                    default:
                        break
                }
            }
            else if(cssClass === "check-types-promo ") {
                switch(tag.name) {
                    case "All":
                        this.labels[tag.name] = t("promo.tag_all")
                        break
                    case "Welcome":
                        this.labels[tag.name] = t("promo.tag_welcome")
                        break
                    case "Edito":
                        this.labels[tag.name] = t("promo.tag_edito")
                        break
                    case "Mobile":
                        this.labels[tag.name] = t("promo.tag_mobile")
                        break
                    case "Landing":
                        this.labels[tag.name] = t("promo.tag_landings")
                        break
                    default:
                        break
                }
            }
        });

        return (
            <>
                {this.sortTags.map((tag, key) => {
                    return (
                        <CheckBox 
                            change={onClick} 
                            cssClass={cssClass+tag+" mr-5 my-2 font-weight-regular font-size-18 c-opale opacity-7"} 
                            label={this.labels[tag]}
                            id={refID?refID+"-"+tag:""}
                            key={key}
                        />
                    )
                })}
            </>
        )
    }
}

export default Presets