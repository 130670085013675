import { Component } from "react"
import PromoPhotoVideo from "./promoPhotoVideo/PromoPhotoVideo"
import PromoText from "./promoText/PromoText"
import Tab from "../../wrapper/tab/Tab"
import { upload } from "../../../api/fetch"
import i18next from "i18next"

class TabsMultimedia extends Component {
    constructor(...args) {
        super(...args)

        this.state = {
            toDeletePhotoVideo: false,
            toDeleteText: false
        }
        this.multimediaSaved = false
        this.promo = this.props.promo
    }

    componentDidMount() {
        if(this.props.action === "EDIT") {
            this.multimediaSaved = true
        }
    }

    async saveMultimediaPromo(file, parameter) {
        const formData = new FormData();
        formData.append("file", file);

        const result = await upload(formData);

        const promo = JSON.parse(JSON.stringify(this.promo));

        if(result.location) {
            promo[parameter] = result.location;
        }

        if(this.multimediaSaved) {
            if(parameter === "picture_landscape" || parameter === "picture_portrait" || parameter === "video_landscape" || parameter === "video_portrait") {
                /* let deleteMultimedia = true */
                if(promo["text_file"].length > 0) {
                    promo["text_file"] = ""
                    /* deleteMultimedia = true */
                }
                    
                this.setState({
                    toDeleteText: true
                })

                this.props.setPromo("multimedia", promo)
            }
            else {
                if(promo["picture_landscape"].length > 0 || promo["picture_portrait"].length > 0 || promo["video_landscape"].length > 0 || promo["video_portrait"].length > 0) {
                    promo["picture_landscape"] = ""
                    promo["picture_portrait"] = ""
                    promo["video_landscape"] = ""
                    promo["video_portrait"] = ""
                }
                this.setState({
                    toDeletePhotoVideo: true
                })

                this.props.setPromo("multimedia", promo)
            } 
        }
        else {
            this.multimediaSaved = true

            this.props.setPromo("multimedia", promo)
        }
       
    }

    resetDelete(parameter) {
        this.setState({[parameter]: false});
    }

    clearUrl(orientation) {
        const promo = JSON.parse(JSON.stringify(this.promo));
        
        if(orientation === "horizontal") {
            promo["picture_landscape"] = ""
            promo["video_landscape"] = ""  
        }
        else {
            promo["picture_portrait"] = ""
            promo["video_portrait"] = ""  
        }
       

        this.props.setPromo("multimedia", promo)
    }

    render() {
        const t = (value) => i18next.t(value)

        const multimediaLandscape = this.props.promo.picture_landscape !== "" ? this.props.promo.picture_landscape : this.props.promo.video_landscape
        const multimediaPortrait = this.props.promo.picture_portrait !== "" ? this.props.promo.picture_portrait : this.props.promo.video_portrait
        const textFile = this.props.promo.text_file

        const tabsConf=[
            {
                text: t("promo.tab_multimedia"),
                content: <PromoPhotoVideo
                            multimediaLandscape={multimediaLandscape} 
                            multimediaPortrait={multimediaPortrait}
                            clearUrl={this.clearUrl.bind(this)} 
                            toDeletePhotoVideo={this.state.toDeletePhotoVideo} 
                            saveMultimediaPromo={this.saveMultimediaPromo.bind(this)} 
                            resetDelete={this.resetDelete.bind(this)}
                        />
            },
            {
                text: t("promo.tab_text"),
                content: <PromoText 
                            textFile={textFile} 
                            toDeleteText={this.state.toDeleteText} 
                            saveMultimediaPromo={this.saveMultimediaPromo.bind(this)} 
                            resetDelete={this.resetDelete.bind(this)}
                        />
            }
        ]

        return (
            <Tab conf={tabsConf} cssClass="form-promo-tab"/>
        )
    }
}

export default TabsMultimedia