import { Component } from "react"
import { Internationalization } from '@syncfusion/ej2-base';
import videoPreview from "../../../images/video_dash.png"
import textPreview from "../../../images/text_dash.png"
import pinOrange from "../../../images/pin_orange@3x.png"
import pinBlue from "../../../images/pin_blue@3x.png"
import crownBlue from "../../../images/crown_blue@3x.png"
import i18next from "i18next";

class PromoToday extends Component {
    constructor(...args) {
        super(...args)

        this.state = {
            loaded: false,
            promotion: {}
        }

        this.instance = new Internationalization();        
    }

    componentDidMount() {
        this.setState({
            promotion: this.props.promotion,
            loaded: true
        })
    }

    getDateString(value) {
        value = new Date(value)
        return this.instance.formatDate(value, { skeleton: 'long' });
    }

    render() {
        const t = (value) => i18next.t(value)

        const { promotion } = this.state;

        if(!this.state.loaded) {
            return <div>loading</div>
        }
        else {
            const cPromo = promotion.visibility === "COMPANY_USER" ? " c-prussia" : " c-carotina"
            const iconTime = promotion.visibility === "COMPANY_USER" ? " custom-time-prussia" : " custom-time-carotina"
            const iconDate = promotion.visibility === "COMPANY_USER" ? " custom-date-prussia" : " custom-date-carotina"

            let image = null
            if(promotion.picture_landscape !== "") {
                image = promotion.picture_landscape
            }
            else if(promotion.picture_portrait !== "") {
                image = promotion.picture_portrait
            }
            else if(promotion.video_landscape !== "") {
                image = videoPreview
            }
            else if(promotion.video_portrait !== "") {
                image = videoPreview
            }
            else {
                image = textPreview
            }

            return (
                <div className="row py-3 px-4 h-100">
                    <div className="col-12 h-20">
                        <div className="row m-0">
                            <p className="m-0 mb-2 font-size-23 font-weight-regular c-opale">{t("home.promotion_label")}</p>
                        </div>
                        <div className="row m-0">
                            <div className="col-auto p-0">
                                {promotion.visibility === "COMPANY_USER" ? <img style={{width: "20px", height:"15px"}} className="mr-1 mt-1" src={crownBlue} alt=""/> : null}
                            </div>
                            <div className="col p-0 text-truncate">
                                <p className="d-inline m-0 mb-2 font-size-25 font-weight-bold c-opale">{promotion.name}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 p-0 d-flex h-50">
                        <div className="col-auto d-flex align-items-center">
                            <img alt="" style={{width: "250px", height: "140px"}} className="rounded-l my-2" src={image}/>
                        </div>
                        <div className="col d-flex flex-column justify-content-center">
                            <div className="row">
                                <div className="col-6 d-flex flex-column">
                                    <p className={"m-0 font-weight-bold font-size-18 opacity-5"+cPromo}>{t("home.start_date")}</p>
                                    <p className={"font-weight-bold font-size-18 c-opale"+iconDate}>{this.getDateString(promotion.starts_at)}</p>
                                </div>
                                <div className="col-6 d-flex flex-column">
                                    <p className={"m-0 font-weight-bold font-size-18 opacity-5"+cPromo}>{t("home.end_date")}</p>
                                    <p className={"font-weight-bold font-size-18 c-opale"+iconDate}>{this.getDateString(promotion.ends_at)}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 d-flex flex-column">
                                    <p className={"m-0 font-weight-bold font-size-18 opacity-5"+cPromo}>{t("home.start_time")}</p>
                                    <p className={"font-weight-bold font-size-18 c-opale"+iconTime}>{promotion.starts_time_at}</p>
                                </div>
                                <div className="col-6 d-flex flex-column">
                                    <p className={"m-0 font-weight-bold font-size-18 opacity-5"+cPromo}>{t("home.end_time")}</p>
                                    <p className={"font-weight-bold font-size-18 c-opale"+iconTime}>{promotion.ends_time_at}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row w-100 m-0 mt-3 h-30 overflow-auto">
                        {promotion.restaurants.map((restaurant, key) => {
                            return (
                                <div key={key} className="col-4 d-flex text-nowrap">
                                    <div className="col-auto p-0">
                                        <img alt="" style={{width: "15px", height: "20px"}} src={promotion.visibility === "COMPANY_USER" ? pinBlue : pinOrange}/>
                                    </div>
                                    <div className="col-auto p-0 text-wrap">
                                        <p className="col-auto d-inline p-0 ml-2 font-weight-regular font-size-16 c-opale m-0">{restaurant.name+","}</p>
                                        <p className="col-auto d-inline p-0 ml-2 font-weight-bold font-size-16 c-opale m-0">{restaurant.site_name}</p>
                                    </div>
                                </div>
                            )
                        })}    
                    </div>                    
                </div>
            )
        }
    }
}

export default PromoToday