import { Component } from "react";
import { fetchFluidityCompanyTotal, fetchPromotions } from "../../api/fetch";
import PromoToday from "./cards/PromoToday";
import BarChart from "../common/barChart/BarChart"
import { DashboardLayoutComponent } from "@syncfusion/ej2-react-layouts"
import { getCurrentDateWithTZ } from "../../utility/tz";
import i18next from "i18next";

class HomeDashboard extends Component {
    constructor(...args) {
        super(...args)

        this.state = {
            loaded: false,
            promotionToday: [],
            fluidityCompanyTotal: {},
            allowDragging: false
        }

        this.panels = []
    }

    async componentDidMount() {
        const currentdate = new Date(); 
        const datetime = currentdate.getFullYear() + "-" + ((currentdate.getMonth()+1) < 10 ? "0" + (currentdate.getMonth()+1) : (currentdate.getMonth()+1)) + "-" + (currentdate.getDate() < 10 ? "0" + currentdate.getDate() : currentdate.getDate());
        const promotionToday = await fetchPromotions(datetime, datetime);
        const currentdateFluidity = getCurrentDateWithTZ()
        const fluidityCompanyTotal = await fetchFluidityCompanyTotal(currentdateFluidity);

        let promoTodayVerified = {promotions: []}

        if(promotionToday !== "there aren't promotions for this period") {
            promotionToday.promotions.forEach(promotion => {
                let isPromoActive = true
                isPromoActive = !isPromoActive ? false : this.verifyTime(promotion.starts_time_at, promotion.ends_time_at)
                isPromoActive = !isPromoActive ? false : this.verifyTrigger(promotion.restaurants)

                if(isPromoActive) {
                    promoTodayVerified.promotions.push(promotion)
                }
            })
        }  

        this.setState({
            promotionToday: promotionToday === "there aren't promotions for this period" ? {"promotions": []} : promoTodayVerified,
            fluidityCompanyTotal: fluidityCompanyTotal,
            loaded: true
        })
    }

    verifyTime(start, end) {
        let now = new Date()
        
        const minutes = now.getMinutes()
        const hours = now.getHours() < 10 ? "0"+now.getHours() : now.getHours()

        start = start.split(":")[0]+start.split(":")[1]
        now = hours+""+minutes
        end = end.split(":")[0]+end.split(":")[1]

        return start<now && now<end
    }

    verifyTrigger(restaurants) {
        let isActive = true
        restaurants.forEach(restaurant => {
            if(restaurant.region.id !== 0) {
                if(!restaurant.region.level_reached) {
                    isActive = false
                }
            }
        })

        return isActive
    }

    isNotUneven(number) {
        if (isNaN(number) === false) {
            return (number %2 === 1 ?  true : false);
        }
        else {
            return null;
        }
    }

    render() {
        const { loaded, promotionToday, fluidityCompanyTotal } = this.state;
        const t = (value) => i18next.t(value)

        if(!loaded) {
            return <div>loading</div>
        }
        else {
            return (
                <div>
                    <div className="col-12 p-0">
                        <p className="font-weight-regular font-size-18 c-opale">{t("home.lunch_dinner")}</p>
                    </div>
                    <div className="control-section">
                        <DashboardLayoutComponent allowDragging={this.state.allowDragging} id='home-dashboard' cellSpacing={[20, 20]} cellAspectRatio={100 / 70} columns={12}>
                            {fluidityCompanyTotal.company && fluidityCompanyTotal.company.sites.length > 3 ?
                                <div id="panel-chart" className="e-panel rounded-l" data-row="0" data-col="0" data-sizex="8" data-sizey="4">
                                    <div className="e-panel-container">
                                        <div className="e-panel-content">
                                            <BarChart sites={fluidityCompanyTotal.company.sites} number={" "+fluidityCompanyTotal.company.users_total}/>
                                        </div>
                                    </div>
                                </div>
                            :
                            <>
                                <div id="panel-hide-1" className="e-panel rounded-l" style={{visibility:"hidden"}} data-row="0" data-col="0" data-sizex="4" data-sizey="4">
                                    <div className="e-panel-container">
                                        <div className="e-panel-content">
                                            
                                        </div>
                                    </div>
                                </div>
                                <div id="panel-chart" className="e-panel rounded-l" data-row="0" data-col="0" data-sizex="4" data-sizey="4">
                                    <div className="e-panel-container">
                                        <div className="e-panel-content">
                                            <BarChart sites={fluidityCompanyTotal.company ? fluidityCompanyTotal.company.sites : []} number={" "+fluidityCompanyTotal.company.users_total}/>
                                        </div>
                                    </div>
                                </div>
                            </>
                            }
                            {promotionToday.promotions.map((promotion, index) => {
                                return this.isNotUneven(index) ?
                                    <div key={index} id={"panel-promo-"+index} className="e-panel rounded-l" data-row="4" data-col="6" data-sizex="6" data-sizey="4">
                                        <div className="e-panel-container">
                                            <div className="e-panel-content">
                                                <PromoToday promotion={promotion}/>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    <div key={index} id={"panel-promo-"+index} className="e-panel rounded-l" data-row="4" data-col="0" data-sizex="6" data-sizey="4">
                                        <div className="e-panel-container">
                                            <div className="e-panel-content">
                                                <PromoToday promotion={promotion}/>
                                            </div>
                                        </div>
                                    </div>
                            })}
                        </DashboardLayoutComponent>
                    </div>
                </div>
            )
        }
    }
}

export default HomeDashboard