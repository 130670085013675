import React, { Component } from 'react';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import i18next from 'i18next';
import { L10n } from '@syncfusion/ej2-base';

export class Uploader extends Component {
    constructor(...args) {
        super(...args);
        
        // Uploader component
        this.filesDetails = [];
        this.filesList = [];
        this.filesName = [];
        this.allowedExtensions = '.jpg,.png,.jpeg,.mp4,.3gp,.avi';
    }

    componentDidMount() {
        L10n.load({
            "fr-CH": {
                "uploader": {
                    "dropFilesHint" : "Ou déposer des fichiers ici",
                }
            },
            "en-CH": {
                "uploader": {
                    "dropFilesHint" : "Or drop files here",
                }
            },
            "it-CH": {
                "uploader": {
                    "dropFilesHint" : "O lascia i file qui",
                }
            }
        })
    }

    onSelected(args) {
        if(args.filesData[0].type === "mp4" || args.filesData[0].type === "3gp" || args.filesData[0].type === "avi") {
            const reader = new FileReader();
            reader.addEventListener('load', () => this.props.setVideo(reader.result));
            reader.readAsDataURL(args.filesData[0].rawFile);
        }
        else if(args.filesData && args.filesData.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => this.props.setImage(reader.result));
            reader.readAsDataURL(args.filesData[0].rawFile);
        }
    }

    render() {
        const { 
            cssClass,
            container,
            multiple,
            buttonText,
        } = this.props;
        return (
            <div className={container}>
                <UploaderComponent 
                    cssClass={cssClass}
                    multiple={multiple}
                    allowedExtensions={this.allowedExtensions}
                    selected={this.onSelected.bind(this)}
                    buttons={{browse: buttonText}}
                    locale={i18next.language.split("-")[0]+"-CH"}
                />
            </div>
        );
    }
}

export default Uploader