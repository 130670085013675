import React, { Component } from "react";
import "./navbar.css";
import logo from "../../../images/logo.png"
import { logout } from "../../../api/fetch";
import i18next from "i18next";

class NavbarHorizontal extends Component {
  async handleClick(event) {
    event.preventDefault();

    const result = await logout();
    
    if(result === "logged out") {
      localStorage.setItem("name","");
      window.location.replace(process.env.REACT_APP_URL_LOGIN_CLIENT);
    }
  }

  render() {
    const t = (value) => i18next.t(value)

    return (
      <nav className="navbar navbar-horizontal fixed-top bg-white p-0 shadow w-100">
        <div className="navbar-item logo d-flex h-100 align-content-center">
          <img className="navbar-logo pt-3 pb-3" src={logo} alt=""/>
        </div>
        <div className="navbar-item d-flex float-right align-content-center px-4 quitter">
            <span className="nav-name-user text-center font-weight-bold c-white mx-2">{localStorage.getItem("name") ? localStorage.getItem("name").slice(0,1).toUpperCase() : ""}</span>
            <a onClick={this.handleClick} className="logout" href="/">{t("navbar.logout")}</a>
        </div>
      </nav>
    );
  }
}

export default NavbarHorizontal;
