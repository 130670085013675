import i18next from "i18next";
import { Component } from "react";
import ColorPicker from "../../../../../wrapper/colorPicker/ColorPicker";

class Background extends Component {
    render() {
        const t = (value) => i18next.t(value)

        const { 
            onBackgroundColorPickerChange,
            backgroundColor,
            disabled
        } = this.props;

        return (
            <div className="row mb-3 justify-content-between">
                <div className="col-auto d-flex align-items-center">
                    <p className="font-weight-bold font-size-19 c-opale m-0">{t("promo.text_background")}</p>
                </div>
                <div className="col-auto">
                    <ColorPicker
                        modeSwitcher={false} 
                        cssClass={"promo-text-color-picker"+disabled}
                        change={onBackgroundColorPickerChange} 
                        value={backgroundColor}
                    />
                </div>
            </div>
        )
    }
}

export default Background