import React, { Component } from "react";
import "./navbar.css";
import dashboard from "../../../images/dashboard.svg";
import promo from "../../../images/promo.svg";
import fluidity from "../../../images/fluidity.svg";
import LanguageSelector from "./languageSelector/LanguageSelector";

class NavbarVertical extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
    };
  }

  componentDidMount() {
    const { tab } = this.props;

    document.getElementById(tab).classList.add("active");
  }

  gotoDash() {
    const { history } = this.props;
    history.push("/");
  } 

  gotoPromoPlanning() {
    const { history } = this.props;
    history.push("/planning");
  } 

  gotoFluidity() {
    const { history } = this.props;
    history.push("/fluidity");
  } 

  render() {
    return (
      <nav className="navbar-vertical bg-white shadow" style={{height: "calc(100vh - 60px)"}}>
        <div
          id="dash"
          className="navbar-item d-flex w-100 align-items-center justify-content-center my-4"
          onClick={this.gotoDash.bind(this)}
        >
          <img className="navbar-icon c-prussia opacity-5"src={dashboard} alt=""/>
        </div>
        <div 
          id="promo_planning"
          className="navbar-item d-flex w-100 align-items-center justify-content-center my-4"
          onClick={this.gotoPromoPlanning.bind(this)}
        >
          <img className="navbar-icon c-prussia opacity-5"src={promo} alt=""/>
        </div>
        <div
          id="fluidity" 
          className="navbar-item d-flex w-100 align-items-center justify-content-center my-4"
          onClick={this.gotoFluidity.bind(this)}
        >
          <img className="navbar-icon c-prussia opacity-5"src={fluidity} alt=""/>
        </div>
        <LanguageSelector/>
      </nav>
    );
  }
}

export default NavbarVertical;
