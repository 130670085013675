import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from "@syncfusion/ej2-react-navigations";
import { Component } from "react";

class Accordion extends Component {
    render() {
        const { 
            contents,
            cssClass,
            style
        } = this.props;

        return (
            <AccordionComponent>
                <AccordionItemsDirective>
                    {contents.map((component, key) => {
                        return <AccordionItemDirective
                            cssClass={cssClass+" "+component.id} 
                            style={style}
                            expanded='true' 
                            header={component.header} 
                            content={component.content} 
                            key={key}                           
                        />
                    })}
                </AccordionItemsDirective>
            </AccordionComponent>
        )
    }
}

export default Accordion