import { Component } from "react"
import NavbarHorizontal from "./../components/common/navbar/NavbarHorizontal"
import NavbarVertical from "./../components/common/navbar/NavbarVertical"
import FluidityDashboard from "../components/fluidityCompany/FluidityDashboard"

class FluidityCompany extends Component {
    render() {
        const { history } = this.props;
        
        return (
            <div className="container-fluid fluidity-bg">
                <div className="row">
                <NavbarHorizontal history={history}/>
                </div>
                <div className="row">
                <NavbarVertical history={history} tab="fluidity"/>
                <div className="content-page w-100 h-auto bg-transparent rounded-l p-0">
                    <FluidityDashboard history={history}/>
                </div>
                </div>
            </div>
        )
    }
}

export default FluidityCompany