import React, { Component } from "react";
import RestaurantsList from "./RestaurantsList";
import Accordion from "../../wrapper/accordion/Accordion"
import Presets from "./Presets";
import "./accordion.css"
import CheckBox from "../../wrapper/checkBox/checkBox";
import i18next from "i18next";

class SitesList extends Component {
    constructor(...args) {
        super(...args)

        this.state = {
            firstRender: true
        }

        this.promo = this.props.promo
        this.triggers = this.props.triggers
    }

    addRestaurant(restaurant) {
        const promo = JSON.parse(JSON.stringify(this.promo));
        const newRestaurant = JSON.parse(JSON.stringify(restaurant));
        
        let exist = false
        promo.restaurants.forEach((restaurantAdded, index) => {
            if(restaurantAdded.place_id === parseInt(newRestaurant.place_id, 10) && restaurantAdded.tag_id === newRestaurant.tag_id) {
                newRestaurant.place_id = parseInt(newRestaurant.place_id, 10)
                newRestaurant.region_id = restaurantAdded.region_id
                newRestaurant.capacity_level = restaurantAdded.capacity_level
                newRestaurant.level_reached = restaurantAdded.level_reached
                promo.restaurants[index] = newRestaurant
                exist = true
            }
        })

        if(!exist) {
            newRestaurant.place_id = parseInt(newRestaurant.place_id, 10)
            promo.restaurants.push(newRestaurant)
        }

        this.props.setPromo("restaurants", promo)
    }

    deleteRestaurant(restaurant) {
        const promo = JSON.parse(JSON.stringify(this.promo));
        const newRestaurant = JSON.parse(JSON.stringify(restaurant));
        
        let restaurantsSelectedRemained = []
        promo.restaurants.forEach((restaurantAdded, index) => {
            if(restaurantAdded.place_id === parseInt(newRestaurant.place_id, 10) && restaurantAdded.tag_id === newRestaurant.tag_id) {
                delete promo.restaurants[index]
            }
            let restaurantsRemained = []
            promo.restaurants.forEach((restaurantRemained) => {
                restaurantsRemained.push(restaurantRemained)
            })
            restaurantsSelectedRemained = restaurantsRemained
        })

        promo.restaurants = restaurantsSelectedRemained

        this.props.setPromo("restaurants", promo)
    }

    setTrigger(data) {
        const triggers = JSON.parse(JSON.stringify(this.triggers));

        let isAdded = false
        triggers.forEach(trigger => {
            if(trigger.place_id === data.restaurantID) {
                trigger.region_id = data.region
                trigger.capacity_level = data.trigger
                trigger.level_reached = false
                isAdded = true
            }
        })

        if(!isAdded)
            triggers.push({place_id: data.restaurantID, region_id: data.region, capacity_level: data.trigger, level_reached: false})

        this.triggers = triggers
        this.props.setPromo("trigger", null, triggers)
    }

    unsetTrigger(restaurantID) {
        const triggers = JSON.parse(JSON.stringify(this.triggers));

        let triggerSelectedRemained = []
        triggers.forEach((trigger, index) => {
            let toUpJson = false
            if(trigger.place_id === restaurantID) {
                delete triggers[index]
                toUpJson = true
            }

            if(toUpJson) {
                triggers.forEach((triggerRemained) => {
                    triggerSelectedRemained.push(triggerRemained)
                })
                this.triggers = triggerSelectedRemained
            }
        })

        this.props.setPromo("trigger", null, this.triggers)
    }

    checkTheBoxPreset(event) {
        const { sites } = this.props;

        const checkboxSplitID = event.event.currentTarget.id.split("-")

        sites.forEach(site => {
            if(site.restaurants) {
                if(event.checked) {
                    if(document.querySelector("div[aria-checked='false'] input[id='site-"+site.id+"']")) {
                        document.querySelector("div[aria-checked='false'] input[id='site-"+site.id+"']").parentNode.parentNode.classList.add("company")
                        document.querySelector("div[aria-checked='false'] input[id='site-"+site.id+"']").parentNode.parentNode.classList.add("disabled")
                        document.querySelector("div[aria-checked='false'] input[id='site-"+site.id+"']").click()
                    }
                    if(document.querySelector("div[aria-checked='true'] input[id='site-"+site.id+"']")) {
                        document.querySelector("div[aria-checked='true'] input[id='site-"+site.id+"']").parentNode.parentNode.classList.add("company")
                        document.querySelector("div[aria-checked='true'] input[id='site-"+site.id+"']").parentNode.parentNode.classList.add("disabled")
                    }
                    if(checkboxSplitID[1] === "All"){
                        document.querySelectorAll("input:not([id$='All'])[id^='"+checkboxSplitID[0]+"-']").forEach(presetToLock => {
                            presetToLock.parentNode.parentNode.classList.add("company");
                            presetToLock.parentNode.parentNode.classList.add("disabled");
                        })
                        if(document.querySelectorAll("div[aria-checked='true'] input[id$='-Welcome']").length > 0)
                            document.querySelectorAll("div[aria-checked='true'] input[id$='-Welcome']").forEach(presetToLock => {
                                presetToLock.click();
                            })
                        if(document.querySelectorAll("div[aria-checked='true'] input[id$='-Edito']").length > 0)
                            document.querySelectorAll("div[aria-checked='true'] input[id$='-Edito']").forEach(presetToLock => {
                                presetToLock.click();
                        })
                        if(document.querySelectorAll("div[aria-checked='true'] input[id$='-Mobile']").length > 0)
                            document.querySelectorAll("div[aria-checked='true'] input[id$='-Mobile']").forEach(presetToLock => {
                                presetToLock.click();
                            })
                        if(document.querySelectorAll("div[aria-checked='true'] input[id$='-Landing']").length > 0)
                            document.querySelectorAll("div[aria-checked='true'] input[id$='-Landing']").forEach(presetToLock => {
                                presetToLock.click();
                            })
                    }
                    else {
                        document.querySelector("input[id='"+checkboxSplitID[0]+"-All']").parentNode.parentNode.classList.add("company")
                        document.querySelector("input[id='"+checkboxSplitID[0]+"-All']").parentNode.parentNode.classList.add("disabled")
                        if(document.querySelectorAll("div[aria-checked='true'] input[id$='-All']").length > 0)
                            document.querySelectorAll("div[aria-checked='true'] input[id$='-All']").forEach(presetToLock => {
                                presetToLock.click();
                            })
                    }
                    document.querySelector("input[id='site-"+site.id+"-"+checkboxSplitID[1]+"']").parentNode.parentNode.classList.add("company")
                    document.querySelector("input[id='site-"+site.id+"-"+checkboxSplitID[1]+"']").parentNode.parentNode.classList.add("disabled")
                    if(document.querySelector("div[aria-checked='false'] input[id='site-"+site.id+"-"+checkboxSplitID[1]+"']"))
                        document.querySelector("div[aria-checked='false'] input[id='site-"+site.id+"-"+checkboxSplitID[1]+"']").click()
                }
                else {
                    if(document.querySelectorAll("div[aria-checked='true'] input[id='site-"+site.id+"']").length > 0 &&
                    document.querySelectorAll("div[aria-checked='true'] input[id^='"+checkboxSplitID[0]+"-']").length === 0) {
                        document.querySelectorAll("div[aria-checked='true'] input[id='site-"+site.id+"']").forEach(checkboxToClick => {
                            checkboxToClick.parentNode.parentNode.classList.remove("company")
                            checkboxToClick.parentNode.parentNode.classList.remove("disabled")
                        })
                    }
                    if(checkboxSplitID[1] === "All"){
                        document.querySelectorAll("input:not([id$='All'])[id^='"+checkboxSplitID[0]+"-']").forEach(presetToLock => {
                            presetToLock.parentNode.parentNode.classList.remove("company");
                            presetToLock.parentNode.parentNode.classList.remove("disabled");
                        })
                    }
                    else {
                        if(document.querySelectorAll("div[aria-checked='true'][class~='check-sites']").length === 0) {
                            document.querySelector("input[id='"+checkboxSplitID[0]+"-All']").parentNode.parentNode.classList.remove("company");
                            document.querySelector("input[id='"+checkboxSplitID[0]+"-All']").parentNode.parentNode.classList.remove("disabled")
                        }
                    }
                    document.querySelector("input[id='site-"+site.id+"-"+checkboxSplitID[1]+"']").parentNode.parentNode.classList.remove("company")
                    document.querySelector("input[id='site-"+site.id+"-"+checkboxSplitID[1]+"']").parentNode.parentNode.classList.remove("disabled")
                    if(document.querySelector("div[aria-checked='true'] input[id='site-"+site.id+"-"+checkboxSplitID[1]+"']"))
                        document.querySelector("div[aria-checked='true'] input[id='site-"+site.id+"-"+checkboxSplitID[1]+"']").click()
                }
            }
        })
    }

    checkTheBoxCustom(event) {
        const checkboxSplitID = event.event.currentTarget.id.split("-")

        if(event.checked) {
            document.getElementsByClassName("accordion-"+checkboxSplitID[0]+"-"+checkboxSplitID[1])[0].classList.remove("d-none")
        }
        else {
            if(document.querySelectorAll("div[class~='accordion-"+checkboxSplitID[0]+"-"+checkboxSplitID[1]+"'] div[aria-checked='true'] input[id^='site']").length > 0) {
                document.querySelectorAll("div[class~='accordion-"+checkboxSplitID[0]+"-"+checkboxSplitID[1]+"'] div[aria-checked='true'] input[id^='site']").forEach(checked => {
                    checked.click();
                })
            }
            if(document.querySelectorAll("div[class~='accordion-"+checkboxSplitID[0]+"-"+checkboxSplitID[1]+"'] div[aria-checked='true'] input[id^='restaurant']").length > 0) {
                document.querySelectorAll("div[class~='accordion-"+checkboxSplitID[0]+"-"+checkboxSplitID[1]+"'] div[aria-checked='true'] input[id^='restaurant']").forEach(checked => {
                    checked.click();
                })
            }
            document.getElementsByClassName("accordion-"+checkboxSplitID[0]+"-"+checkboxSplitID[1])[0].classList.add("d-none")
        }
    }

    contentRestaurantList(site) {
        const { tags, promo } = this.props;
        this.setState({firstRender: false})

        return (
            <RestaurantsList 
                restaurants={site.restaurants} 
                tags={tags} 
                siteName={site.name} 
                siteID={site.id}
                addRestaurant={this.addRestaurant.bind(this)} 
                deleteRestaurant={this.deleteRestaurant.bind(this)}
                promo={promo}
                setTrigger={this.setTrigger.bind(this)}
                unsetTrigger={this.unsetTrigger.bind(this)}
            />
        )
    }

    render() {
        const t = (value) => i18next.t(value)

        const { sites, tags } = this.props;

        const contents = []
        if(this.state.firstRender) {
            sites.forEach(site => {
                if(site.restaurants)
                contents.push({id:"accordion-site-"+site.id, header: `<p class='font-weight-bold font-size-30 c-opale m-0 my-2'>${site.name}</p>`, content: this.contentRestaurantList.bind(this, site)})
            })
        }

        return (
            <div className="row pt-2">
                <div className="col-12">
                    <p className="font-weight-bold font-size-24 c-prussia mb-1">{t("promo.preset_title")}</p>
                </div>
                <div className="col-12">
                    <Presets 
                        cssClass={"check-sites "}
                        tags={tags}
                        refID={"company"}
                        onClick={this.checkTheBoxPreset.bind(this)}
                    />
                </div>
                <div className="col-12">
                    <p>{t("promo.or")}...</p>
                </div>
                <div className="col-12">
                    <p className="font-weight-bold font-size-24 c-prussia mb-1">{t("promo.custom_title")}</p>
                </div>
                <div className="col-12">
                    <p className="font-weight-bold font-size-18 c-prussia opacity-5 mb-1">{t("promo.custom_site_subtitle")}</p>
                </div>
                <div className="col-6 mb-3">
                    {sites.map((site, key) => {     
                        return site.restaurants ?
                            <CheckBox 
                                cssClass="check-site col-4 p-0 my-2 font-weight-regular font-size-18 c-opale opacity-7" 
                                id={"site-"+site.id}
                                label={site.name}
                                change={this.checkTheBoxCustom.bind(this)}
                                key={key}
                            />
                        : null
                    })}
                </div>
                <div className="col-12">
                    <Accordion cssClass="d-none border borderb" contents={contents}/>
                </div>
            </div>
        )
    }
}

export default SitesList