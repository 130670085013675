import React, { Component } from 'react';
import { Internationalization } from '@syncfusion/ej2-base';
import "./event-schedule.css"
import crownWhite from "../../../../images/crown_white.png"

export class EventScheduleMonth extends Component {
    constructor(...args) {
        super(...args);
        
        this.data = this.props.element;
        this.instance = new Internationalization();
    }

    componentDidMount() {
        if(document.querySelectorAll(".company-event-month").length > 0) {
            document.querySelectorAll(".company-event-month").forEach(companyEventMonth => {
                companyEventMonth.parentNode.parentNode.parentNode.classList.add("bg-prussia");
            })
        }
        if(document.querySelectorAll(".site-event-month").length > 0) {
            document.querySelectorAll(".site-event-month").forEach(companyEventMonth => {
                companyEventMonth.parentNode.parentNode.parentNode.classList.add("bg-carotina");
            })
        }
    }

    getDateString(value) {
        return this.instance.formatDate(value, { skeleton: 'long' });
    }

    render() {
        return (
        <div className={this.data.visibility === "COMPANY_USER" ? "row m-0 pl-1 company-event-month" : "row m-0 pl-1 site-event-month"} style={{flexWrap: "nowrap"}}>
            {this.data.visibility === "COMPANY_USER" ? <div className="col-auto p-0"><img alt="" style={{width: "15px", height: "15px"}} className="mr-1" src={crownWhite}/></div> : null}
            <div className="col p-0 text-truncate"><span className="font-weight-bold">{this.data.name}</span></div>
        </div>
        );
    }
}

export default EventScheduleMonth