import { Component } from "react";
import { Internationalization } from '@syncfusion/ej2-base';
import ominoGray from "../../../images/omino_gray@3x.png"
import i18next from "i18next";

class CompanyComparison extends Component {
    constructor(...args) {
        super(...args)

        this.instance = new Internationalization();
    }

    getDate(week) {
        let today = new Date()
        today = new Date(today.getTime() - (60*60*24*7*1000*week));
        
        return this.getDateString(today)
    }

    getDateString(value) {
        return this.instance.formatDate(value, { skeleton: 'long' });
    }

    render() {
        const t = (value) => i18next.t(value)

        const { orientation, comparison } = this.props;

        if(orientation === "vertical") {
            return (
                <div className="row mt-3 mb-3">
                    <div className="col-12 d-flex flex-column align-items-center">
                        <div className="col-8 p-0">
                            <p className="font-weight-bold font-size-33 c-opale">{t("fluidity_company.week_comparison")}</p>
                        </div>
                    </div>
                    <div className="col-12 d-flex flex-column align-items-center">
                        {comparison.map((week, key) => {
                            return (
                                <div key={key} className={week.percentage.split("%")[0]<"0"? "col-8 mb-4 p-0 d-flex rounded-m border-red mt-2 mb-2" : "col-8 mb-4 p-0 d-flex rounded-m border-green mt-2 mb-2"}>
                                    <div className={week.percentage.split("%")[0]<"0"? "col-auto p-4 bg-red" : "col-auto p-4 bg-green"}>
                                        <p className="font-weight-bold font-size-38 c-white m-0">{week.percentage}</p>
                                    </div>
                                    <div className="row m-0" style={{flex:"1"}}>
                                        <div className="col-12 text-center">
                                            <p className="font-weight-regular font-size-19 c-opale mt-2 mb-0">{this.getDate(week.week_ago)}</p>
                                        </div>
                                        <div className="col text-center">
                                            <p className="font-weight-bold font-size-45 c-opale opacity-7 m-0 d-inline-block w-auto">{week.users_total}</p>
                                            <img alt="" className="omino gray" src={ominoGray}/>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="row h-auto m-auto py-4 px-3">
                    <div className="col-12">
                        <p className="font-weight-bold font-size-33 c-opale">{t("fluidity_company.week_comparison")}</p>
                    </div>
                    <div className="col-12 d-flex">
                        {comparison.map((week, key) => {
                            return (
                                <div key={key} style={{width: "24%", marginRight: "1%"}} className={week.percentage.split("%")[0]<"0"? "p-0 d-flex rounded-m border-red mt-2 mb-2" : "p-0 d-flex rounded-m border-green mt-2 mb-2"}>
                                    <div className={week.percentage.split("%")[0]<"0"? "col-auto p-4 bg-red" : "col-auto p-4 bg-green"}>
                                        <p className="font-weight-bold font-size-38 c-white m-0">{week.percentage}</p>
                                    </div>
                                    <div className="row m-0" style={{flex:"1"}}>
                                        <div className="col-12 text-center">
                                            <p className="font-weight-regular font-size-19 c-opale mt-2 mb-0">{this.getDate(week.week_ago)}</p>
                                        </div>
                                        <div className="col text-center">
                                            <p className="font-weight-bold font-size-45 c-opale opacity-7 m-0 d-inline-block w-auto">{week.users_total}</p>
                                            <img alt="" className="omino gray" src={ominoGray}/>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        }
    }
}

export default CompanyComparison