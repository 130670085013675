import { Component } from "react";
import ColorPicker from "../../../../../wrapper/colorPicker/ColorPicker"
import DropDownList from "../../../../../wrapper/dropDown/DropDownList"
import { fontList } from "../../../../../../font/font"
import i18next from "i18next";

class Text extends Component {
    constructor(...args) {
        super(...args)

        this.state = {
            titleSizeItems: [
                {size: "35", property: 35},
                {size: "36", property: 36},
                {size: "37", property: 37},
                {size: "38", property: 38},
                {size: "39", property: 39},
                {size: "40", property: 40},
                {size: "41", property: 41},
                {size: "42", property: 42},
                {size: "43", property: 43},
                {size: "44", property: 44},
                {size: "45", property: 45},
            ],
            subtitleSizeItems: [
                {size: "20", property: 20},
                {size: "21", property: 21},
                {size: "22", property: 22},
                {size: "23", property: 23},
                {size: "24", property: 24},
                {size: "25", property: 25},
                {size: "26", property: 26},
                {size: "27", property: 27},
                {size: "28", property: 28},
                {size: "29", property: 29},
                {size: "30", property: 30},
            ]
        }
    }

    componentDidMount() {
        const { conf } = this.props;

        if(conf.title.bold.length > 0) {
            document.querySelector("#style-title #bold").checked = true
        }
        if(conf.title.italic.length > 0) {
            document.querySelector("#style-title #italic").checked = true
        }
        if(conf.title.underline.length > 0) {
            document.querySelector("#style-title #underline").checked = true
        }
        if(conf.title.lineThrough.length > 0) {
            document.querySelector("#style-title #line-through").checked = true
        }
        if(conf.subtitle.bold.length > 0) {
            document.querySelector("#style-title #bold").checked = true
        }  
        if(conf.subtitle.italic.length > 0) {
            document.querySelector("#style-title #italic").checked = true
        }  
        if(conf.subtitle.underline.length > 0) {
            document.querySelector("#style-title #underline").checked = true
        }
        if(conf.subtitle.lineThrough.length > 0) {
            document.querySelector("#style-title #line-through").checked = true
        }
    }

    render() {
        const t = (value) => i18next.t(value)

        const {
            titleSizeItems,
            subtitleSizeItems
        } = this.state;

        const { 
            typeText,
            onDropdownChange,
            onTitleColorPickerChange,
            onSubtitleColorPickerChange,
            onInputChecked,
            conf,
            disabled
        } = this.props;

        return (
            <>
                <div className="row justify-content-between">
                    <div className="col-auto d-flex align-items-center">
                        <p className="font-weight-bold font-size-19 c-opale m-0">
                            {typeText === "title" ? t("promo.text_title") : t("promo.text_subtitle")}
                        </p>
                    </div>
                    <div className="col-auto">
                        <ColorPicker 
                            modeSwitcher={false} 
                            cssClass={"promo-text-color-picker"+disabled}
                            change={typeText === "title" ? onTitleColorPickerChange : onSubtitleColorPickerChange} 
                            value={typeText === "title" ? conf.title.color : conf.subtitle.color}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <p className="font-weight-bold font-size-16 c-prussia m-0">Font</p>
                    </div>
                    <div className="col-12 mb-2">
                        <DropDownList
                            id={typeText === "title" ? "title-family" : "subtitle-family"}
                            fields={{text:"family", value:"property"}}
                            change={onDropdownChange}
                            dataSource={fontList}
                            cssClass={"promo-text-dropdown family"+disabled}
                            value={typeText === "title" ? conf.title.fontFamily : conf.subtitle.fontFamily}
                        />
                    </div>
                    <div className="col-12 mb-2">
                        <div
                            id={typeText === "title" ? "style-title" : "style-subtitle"}
                            className="e-btn-group rounded-m style-button-group w-100"
                        >
                            <input 
                                type="checkbox"
                                id={typeText === "title" ? "title-bold" : "subtitle-bold"}
                                name="fontstyle"
                                value="bold"
                                checked={typeText === "title" ?  conf.title.bold : conf.subtitle.bold}
                                onChange={onInputChecked}
                            />
                            <label 
                                className={"e-btn p-0 w-25 style-box-rounded"+disabled}
                                htmlFor={typeText === "title" ? "title-bold" : "subtitle-bold"}
                            >
                                <span className="e-icons e-bold e-btn-image bold"/>
                            </label>
                            <input
                                type="checkbox"
                                id={typeText === "title" ? "title-italic" : "subtitle-italic"}
                                name="fontstyle"
                                value="italic"
                                checked={typeText === "title" ?  conf.title.italic : conf.subtitle.italic}
                                onChange={onInputChecked}
                            />
                            <label
                                className={"e-btn p-0 w-25 style-box-rounded"+disabled}
                                htmlFor={typeText === "title" ? "title-italic" : "subtitle-italic"}
                            >
                                <span className="e-icons e-italic italic"/>
                            </label>
                            <input
                                type="checkbox"
                                id={typeText === "title" ? "title-underline" : "subtitle-underline"}
                                name="fontstyle"
                                value="underline"
                                checked={typeText === "title" ?  conf.title.underline : conf.subtitle.underline}
                                onChange={onInputChecked}
                            />
                            <label
                                className={"e-btn p-0 w-25 style-box-rounded"+disabled}
                                htmlFor={typeText === "title" ? "title-underline" : "subtitle-underline"}
                            >
                                <span className="e-icons e-underline underline"/>
                            </label>
                            <input
                                type="checkbox"
                                id={typeText === "title" ? "title-line-through" : "subtitle-line-through"}
                                name="fontstyle"
                                value="line-through"
                                checked={typeText === "title" ?  conf.title.lineThrough : conf.subtitle.lineThrough}
                                onChange={onInputChecked}
                            />
                            <label
                                className={"e-btn p-0 w-25 style-box-rounded"+disabled}
                                htmlFor={typeText === "title" ? "title-line-through" : "subtitle-line-through"}
                            >
                                <span className="e-icons e-line-through line-through"/>
                            </label>
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <DropDownList
                            id={typeText === "title" ? "title-size" : "subtitle-size"}
                            fields={{text:"size", value:"property"}}
                            change={onDropdownChange}
                            dataSource={typeText === "title" ? titleSizeItems : subtitleSizeItems}
                            cssClass={"promo-text-dropdown size"+disabled}
                            value={typeText === "title" ? conf.title.fontSize : conf.subtitle.fontSize}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default Text;