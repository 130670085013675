export function fetchApi(url, params) {
    return fetch(process.env.REACT_APP_URL_SERVER+url, params)
    .then((response) => {
      if(response.status && response.status === 401) {
        window.location.replace(process.env.REACT_APP_URL_LOGIN_CLIENT);
      }
      else {
        return response.json();
      }
    })
    .catch((err) => {
      window.location.replace(process.env.REACT_APP_URL_LOGIN_CLIENT);
    });
}