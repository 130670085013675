import { Component } from "react";
import Cropper from "react-easy-crop";

class EasyCropper extends Component {
    constructor(...args) {
        super(...args)

        this.state = {
            crop: { x: 0, y: 0 }
        }
    }

    onCropChange = crop => {
        this.setState({ crop })
    }
    
    onCropComplete(croppedArea, croppedAreaPixels) { 
        const { image, setCroppedImageUrl } = this.props;
        if (image && croppedAreaPixels.width && croppedAreaPixels.height) {
            setCroppedImageUrl(this.getCroppedImg(image, croppedAreaPixels))
        }
    }

    dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        let croppedImage = new File([u8arr], filename, {type:mime});
        this.setState({croppedImage: croppedImage }) 
    }
    
    getCroppedImg(imageSrc, crop) {
        const image = new Image();

        image.crossOrigin = "*"
        image.src = imageSrc

        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
            )

        return canvas.toDataURL();
    }

    render() {
        const { crop } = this.state;

        const {
            image,
            zoom,
            zoomWithScroll,
            aspect
        } = this.props;

        return (
            <div className="crop-container">
              <Cropper
                image={image}
                crop={crop}
                zoomWithScroll={zoomWithScroll}
                zoom={zoom}
                aspect={aspect}
                onCropChange={this.onCropChange}
                onCropComplete={this.onCropComplete.bind(this)}
              />
            </div>
        )
    }
}

export default EasyCropper