import React, { Component } from "react";
import MultimediaUpload from "./multimediaUpload/MultimediaUpload";

class PromoPhotoVideo extends Component {
    render() {
        //const { t } = this.props;
        const { 
            toDeletePhotoVideo,
            resetDelete,
            clearUrl,
            multimediaLandscape,
            multimediaPortrait
        } = this.props;

        return (
            <div className="row">
                <MultimediaUpload multimedia={multimediaLandscape} clearUrl={clearUrl} resetDelete={resetDelete} toDeletePhotoVideo={toDeletePhotoVideo} saveMultimediaPromo={this.props.saveMultimediaPromo} orientation="horizontal"/>
                <MultimediaUpload multimedia={multimediaPortrait} clearUrl={clearUrl} resetDelete={resetDelete} toDeletePhotoVideo={toDeletePhotoVideo} saveMultimediaPromo={this.props.saveMultimediaPromo} orientation="vertical"/>
            </div>
        );
    }
}

export default PromoPhotoVideo;

