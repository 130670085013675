import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { Component } from "react";

class CheckBox extends Component {
    render() {
        const {
            cssClass,
            change,
            label,
            id,
            checked,
            value
        } = this.props;

        return (
            <CheckBoxComponent
                change={change} 
                cssClass={cssClass} 
                label={label}
                id={id}
                checked={checked}
                value={value}
            />
        )
    }
}

export default CheckBox