export function getCurrentDateWithTZ() {
    let currentdate = new Date();
    let tz = currentdate.getTimezoneOffset();
    let symbol = tz.toString().indexOf("-") !== -1 ? "-" : "+";
    let minutes = tz.toString().replace(symbol, "");
    let hours = Number(minutes)/60;
    symbol = symbol === "-" ? "+" : "-";
    hours = hours < 10 ? "0"+hours+":00" : hours+":00";
    currentdate = `${currentdate.getFullYear()}-${addZero(currentdate.getMonth()+1)}-${addZero(currentdate.getDate())}T${addZero(currentdate.getHours())}:${addZero(currentdate.getMinutes())}:${addZero(currentdate.getSeconds())}`;
    return currentdate.split("Z")[0]+encodeURIComponent(symbol)+hours;
}

function addZero(value) {
    return value < 10 ? `0${value}` : value
}