import React from 'react'
import EasyCropper from '../../../../../wrapper/cropper/EasyCropper'
import Button from '../../../../../wrapper/button/Button'
import Slider from '../../../../../wrapper/slider/Slider'
import whiteCheck from "../../../../../../images/white_check@3x.png"
import i18next from 'i18next'

class MultimediaCropper extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = {
      croppedImageUrl: null,
      zoom: 1
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.src !== this.props.src) {
      this.setState({croppedImageUrl: this.props.src})
    }
    if(!prevProps.toDeletePhotoVideo && this.props.toDeletePhotoVideo) {
      this.setState({
        croppedImageUrl: null
      })
    }

  }

  setCroppedImageUrl(croppedImageUrl) {
    this.setState({
      croppedImageUrl: croppedImageUrl
    })
  }

  onZoomChange = () => {
    const { cropperClass } = this.props;
    this.setState({ zoom: document.querySelector("."+cropperClass+" .e-control.e-slider.e-lib").ej2_instances[0].value })
  }

  deleteFile(event) {
    event.preventDefault();

    const { cropperClass, deleteFile } = this.props;

    this.setState({
      croppedImageUrl: null
    })

    deleteFile(cropperClass);
  }

  render() {
    const t = (value) => i18next.t(value)

    const {
      croppedImageUrl,
      zoom,
    } = this.state;

    const {
      src,
      editMode,
      cropperClass,
      aspect,
      changeMode,
    } = this.props;

    return (
      <div className={cropperClass}>
        {editMode ?
          <div className="mb-3">
            <EasyCropper
              image={src}
              croppedImageUrl={croppedImageUrl}
              setCroppedImageUrl={this.setCroppedImageUrl.bind(this)}
              aspect={aspect}
              zoomWithScroll={false}
              zoom={zoom}
            />
            <Slider 
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              change={this.onZoomChange}
              type='MinRange'
            />
          </div>
          :
          <div className="mb-3">
            <img alt="" className="rounded-l image-cropped" src={croppedImageUrl}/>
          </div>
        }
        {croppedImageUrl && editMode ?
          <Button
            cssClass="save-modification-button save-file-button m-2 bg-green c-white"
            onClick={(event) => changeMode(event, croppedImageUrl)}
            value={<span><img alt="" src={whiteCheck}/>{t("promo.multimedia_save")}</span>}
          />
          :
          null
        }
        {croppedImageUrl && !editMode ?
          <Button
            cssClass="edit-file-button m-2 bg-white c-prussia"
            onClick={(event) => changeMode(event, null)}
            value={<span>{t("promo.multimedia_edit")}</span>}
          />
          :
          null
        }
        {croppedImageUrl ?
          <Button 
            cssClass="delete-file-button m-2 bg-red c-white"
            onClick={this.deleteFile.bind(this)}
            value={t("promo.multimedia_delete_img")}
          />
          :
          null
        }
      </div>
    )
  }
}

export default MultimediaCropper