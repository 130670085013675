import Uploader from "../../../../wrapper/uploader/Uploader";
import MultimediaCropper from "./multimediaCropper/MultimediaCropper";
import VideoReader from "./videoReader/VideoReader";
import { Component } from "react";
import './multimedia-upload.css'
import i18next from "i18next";

class MultimediaUpload extends Component {
    constructor(...args) {
        super(...args);

        this.t = (value) => i18next.t(value)
        this.state = {
            editMode: false,
            upImage: null,
            upVideo: null,
            isImage: true,
            buttonText: this.t("promo.multimedia_upload_browse")+"..."
        };

        this.initialState = {
            editMode: false,
            upImage: null,
            upVideo: null,
            isImage: true,
            buttonText: this.t("promo.multimedia_upload_browse")+"..."
        }
    }

    componentDidMount() {
        const { multimedia } = this.props;
        const extension = multimedia.split(".")[multimedia.split(".").length-1]
        if(extension === "jpg" || extension === "png" || extension === "jpeg") {
            this.setState({
                isImage: true,
                upImage: multimedia
            })
        }
        else if(extension === "mp4" || extension === "3gp" || extension === "avi") {
            this.setState({
                isImage: false,
                upVideo: multimedia
            })
        }
    }

    componentDidUpdate(prevProps) {
        if(!prevProps.toDeletePhotoVideo && this.props.toDeletePhotoVideo) {
            this.deleteFile();
            this.props.resetDelete("toDeletePhotoVideo");
        }
    }
    
    setImage(result) {
        this.setState({
            isImage: true,
            upImage: result,
            editMode: true,
            buttonText: this.t("promo.multimedia_upload_change")
        })
    }

    setVideo(result) {
        this.setState({
            isImage: false,
            upVideo: result,
            buttonText: this.t("promo.multimedia_upload_change")
        })

        const splitResult = result.split("video/")
        const format = splitResult[1].split(";")[0]

        const file = this.dataURLtoFile(result, "video."+format)

        this.props.saveMultimediaPromo(file, this.props.orientation === "horizontal" ? "video_landscape" : "video_portrait")
    }

    dataURLtoFile(dataurl, filename) {
 
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    }

    changeMode(event, result) {
        event.preventDefault();
        
        this.setState({
            editMode: !this.state.editMode
        }, () => {
            if(!this.state.editMode) {    
                const splitResult = result.split("image/")
                const format = splitResult[1].split(";")[0]
    
                const file = this.dataURLtoFile(result, "image."+format)
                this.props.saveMultimediaPromo(file, this.props.orientation === "horizontal" ? "picture_landscape" : "picture_portrait")
            }
        }); 
    }

    deleteFile() {
        if(document.querySelectorAll("div[class^='multimedia'][class$='"+this.props.orientation+"'] .e-file-remove-btn").length > 0)
            document.querySelectorAll("div[class^='multimedia'][class$='"+this.props.orientation+"'] .e-file-remove-btn").forEach(deleteButton => { deleteButton.click(); });

        this.setState(this.initialState)
        
        if(!this.state.editMode) {
            this.setState({editMode: true}, () => {
                this.setState({editMode: false})
            })
        }

        this.props.clearUrl(this.props.orientation)
    }

    render() {
        const { isImage, upImage, upVideo, editMode, buttonText } = this.state;

        const { 
            orientation,
            toDeletePhotoVideo
        } = this.props;

        return (
            <div className="col-auto mt-2">
                {isImage ?
                    <MultimediaCropper
                        src={upImage}
                        changeMode={this.changeMode.bind(this)}
                        deleteFile={this.deleteFile.bind(this)}
                        editMode={editMode}
                        aspect={orientation === "horizontal" ? 16 / 9 : 9 / 16 }
                        cropperClass={orientation === "horizontal" ? "multimedia-cropper-horizontal" : "multimedia-cropper-vertical"}
                        toDeletePhotoVideo={toDeletePhotoVideo}
                    />
                    :
                    <VideoReader
                        videoClass={orientation === "horizontal" ? "multimedia-video-horizontal" : "multimedia-video-vertical"}
                        upVideo={upVideo}
                        deleteFile={this.deleteFile.bind(this)}
                    />
                }
                <Uploader
                    cssClass="custom-uploader"
                    setImage={this.setImage.bind(this)}
                    setVideo={this.setVideo.bind(this)}
                    container={orientation === "horizontal" ? "multimedia-uploader-horizontal" : "multimedia-uploader-vertical"}
                    multiple={false}
                    buttonText={buttonText}
                />
            </div>
        );
    }
}

export default MultimediaUpload;