import React, { Component } from 'react'
import "./screen-preview.css"
import warning from "../../../../../images/warning_yellow@3x.png"
import i18next from 'i18next';

class ScreenPreview extends Component {
    componentDidUpdate(prevProps) {
        if(prevProps.conf.title.text !== this.props.conf.title.text) {
            this.props.textareaHeightCalc();
        }
    }

    onChangeText(event) {
        const { 
            textareaHeightCalc,
            changeConf
        } = this.props;
        
        textareaHeightCalc();
        const conf = JSON.parse(JSON.stringify(this.props.conf));

        if(event.target.name === "title") {
            conf.title.text = event.target.value
        }
        else {
            conf.subtitle.text = event.target.value
        }
        
        changeConf(conf);
    }

    render() {
        const t = (value) => i18next.t(value)

        const {
            conf,
            title_maxh,
            title_h,
            cssClass,
            disabled
        } = this.props;

        return (
            <div className="col-auto mt-2 d-flex flex-column justify-content-center">
                <div 
                    style={conf.background} 
                    className={cssClass+" rounded-l d-flex flex-column align-items-center justify-content-center"}
                >
                    <textarea
                        name="title"
                        maxLength={50}
                        onChange={this.onChangeText.bind(this)}
                        value={conf.title.text}
                        placeholder={t("promo.text_title_placeholder")}
                        className={
                            cssClass === "screen-horizontal" ? 
                                "w-100 p-0 overflow-hidden promo-text-field title mb-textarea-horizontal px-textarea-horizontal"+disabled 
                            : 
                                "w-100 p-0 overflow-hidden promo-text-field title mb-textarea-vertical px-textarea-vertical"+disabled
                        }
                        style={{
                            fontFamily: conf.title.fontFamily,
                            fontSize: cssClass === "screen-horizontal" ? conf.title.fontSize : conf.title.fontSize / 100 * 80.77,
                            fontWeight: conf.title.bold,
                            fontStyle: conf.title.italic,
                            textDecoration: conf.title.underline+" "+conf.title.lineThrough,
                            color: conf.title.color,
                            lineHeight: conf.title.lineHeight,
                            maxHeight: title_maxh,
                            height: title_h,
                        }}
                    />
                    <textarea
                        name="subtitle"
                        rows={cssClass === "screen-horizontal" ? 3 : 5}
                        maxLength={50}
                        onChange={this.onChangeText.bind(this)}
                        value={conf.subtitle.text}
                        placeholder={t("promo.text_subtitle_placeholder")}
                        className={
                            cssClass === "screen-horizontal" ? 
                                "w-100 p-0 overflow-hidden promo-text-field m-0 subtitle px-textarea-horizontal"+disabled
                            :
                                "w-100 p-0 overflow-hidden promo-text-field m-0 subtitle px-textarea-vertical"+disabled
                        }
                        style={{
                            fontFamily: conf.subtitle.fontFamily,
                            fontSize: cssClass === "screen-horizontal" ? conf.subtitle.fontSize : conf.subtitle.fontSize / 100 * 80.77,
                            fontWeight: conf.subtitle.bold,
                            fontStyle: conf.subtitle.italic,
                            textDecoration: conf.subtitle.underline+" "+conf.subtitle.lineThrough,
                            color: conf.subtitle.color,
                            lineHeight: conf.title.lineHeight,
                        }}
                    />
                </div>

                {cssClass === "screen-horizontal" ? 
                    <div className="row alert-preview pt-3 pb-2 px-3 m-auto bg-alert rounded-m">
                        <div className="col-auto p-0">
                            <img alt="" className="image" src={warning}/>
                        </div>
                        <div className="col">
                            <p className="m-0 font-weight-normal font-size-16 font-italic c-opale">
                                {t("promo.text_alert")}
                            </p>
                        </div>
                    </div>    
                : 
                    null
                }
            </div>
        )
    }
}

export default ScreenPreview