import React, { Component } from "react";
import "./form-promo.css";
import { createPromotion, fetchDataForAddPromotion, fetchPromotion, updatePromotion } from "../../api/fetch";
import SitesList from "./list/SitesList";
import Button from "../wrapper/button/Button";
import error from "../../images/warning_red@3x.png"
import FieldTextPromo from "./field/FieldTextPromo";
import Tabs from "./tabsMultimedia/TabsMultimedia";
import i18next from "i18next";

class FormPromo extends Component {
    constructor(...args) {
        super(...args);

        this.state = {
            loaded: false,
            isError: false,
            toDeleteText: false,
            toDeletePhotoVideo: false,
            promo: {
                id: 0,
                starts_at: "",
                ends_at: "",
                name: "",
                description: "",
                picture_landscape: "",
                picture_portrait: "",
                text_file: "",
                video_landscape: "",
                video_portrait: "",
                starts_time_at: "",
                ends_time_at: "",
                restaurants: []
            }
        };

        this.data = {sites: [], tags: []}
        this.multimediaSaved = false
        this.promo = {
            id: 0,
            starts_at: "",
            ends_at: "",
            name: "",
            description: "",
            picture_landscape: "",
            picture_portrait: "",
            text_file: "",
            video_landscape: "",
            video_portrait: "",
            starts_time_at: "",
            ends_time_at: "",
            restaurants: []
        }

        this.triggers = []
    }

    async componentDidMount() {
        const { action } = this.props;
        const dataForAddPromotion = await fetchDataForAddPromotion()
        this.data = dataForAddPromotion
        if(action === "EDIT") {
            const result = await fetchPromotion(this.props.history.location.state.promoID)
            result.promotion.starts_at= new Date(result.promotion.starts_at)
            result.promotion.ends_at= new Date(result.promotion.ends_at)
            this.populateThisPromo(result.promotion)

            this.setState({
                promo: this.promo,
                loaded: true,
            }, () => {
                const multimediaLandscape = this.state.promo.picture_landscape !== "" ? this.state.promo.picture_landscape : this.state.promo.video_landscape
                const multimediaPortrait = this.state.promo.picture_portrait !== "" ? this.state.promo.picture_portrait : this.state.promo.video_portrait
                const textFile = this.state.promo.text_file

                if(textFile !== "" && multimediaLandscape === "" && multimediaPortrait === "") {
                    document.querySelectorAll("div[class~='form-promo-tab'] div[class*='e-item e-toolbar-item']")[1].click();
                }
            })
        }
        else {
            this.setState({loaded: true})
        }
    }

    populateThisPromo(promotion) {
        this.promo.id = promotion.id
        this.promo.name = promotion.name
        this.promo.description = promotion.description
        this.promo.starts_at = promotion.starts_at
        this.promo.ends_at = promotion.ends_at
        this.promo.starts_time_at = promotion.starts_time_at
        this.promo.ends_time_at = promotion.ends_time_at
        this.promo.picture_landscape = promotion.picture_landscape
        this.promo.picture_portrait = promotion.picture_portrait
        this.promo.video_landscape = promotion.video_landscape
        this.promo.video_portrait = promotion.video_portrait
        this.promo.text_file = promotion.text_file
        const restaurants = []
        promotion.restaurants.forEach(restaurant => {
            restaurants.push({
                place_id: restaurant.id,
                tag_id: restaurant.tag.id,
                region_id: restaurant.region.id === 0 ? null : restaurant.region.id,
                capacity_level: restaurant.region.id === 0 ? null : restaurant.region.capacity_level,
                level_reached: restaurant.region.id === 0 ? null : restaurant.region.level_reached
            })
        })
        this.promo.restaurants = restaurants
    }

    changeFormat(date) {
        return date.split("-")[2]+"/"+date.split("-")[1]+"/"+date.split("-")[0]
    }

    setPromo(fields, promo, triggers) {
        if(fields === "multimedia") {
            this.promo.picture_landscape = promo.picture_landscape
            this.promo.picture_portrait = promo.picture_portrait
            this.promo.video_landscape = promo.video_landscape
            this.promo.video_portrait = promo.video_portrait
            this.promo.text_file = promo.text_file
        }
        else if(fields === "text") {
            this.promo.starts_at = promo.starts_at
            this.promo.ends_at = promo.ends_at
            this.promo.starts_time_at = promo.starts_time_at
            this.promo.ends_time_at = promo.ends_time_at
            this.promo.name = promo.name
            this.promo.description = promo.description
        }
        else if(fields === "restaurants") {
            this.promo.restaurants = promo.restaurants
        }
        else if(fields === "trigger") {
            this.triggers = triggers
        }
    }

    async validateFields() {
        if(this.promo.starts_at === "" || this.promo.ends_at === "") {
            document.getElementById("error-promo").classList.remove("d-none");
        }
        else if(this.promo.starts_time_at === "" || this.promo.ends_time_at === "") {
            document.getElementById("error-promo").classList.remove("d-none");
        }
        else if(this.promo.name === "") {
            document.getElementById("error-promo").classList.remove("d-none");
        }
        else if(this.promo.picture_landscape === "" && this.promo.picture_portrait === "" && this.promo.video_landscape === "" && this.promo.video_portrait === "" && this.promo.text_file === "") {
            document.getElementById("error-promo").classList.remove("d-none");
        }
        else if(this.promo.restaurants.length === 0) {
            document.getElementById("error-promo").classList.remove("d-none");
        }
        else {
            this.promo.restaurants.forEach(restaurant => {
                restaurant.region_id = null
                restaurant.capacity_level = null
            })
            this.promo.restaurants.forEach((restaurant, index) => {
                this.triggers.forEach(trigger => {
                    if(restaurant.place_id === trigger.place_id) {
                        this.promo.restaurants[index].region_id = trigger.region_id
                        this.promo.restaurants[index].capacity_level = trigger.capacity_level
                        this.promo.restaurants[index].level_reached = trigger.level_reached
                    }
                })
            })
            
            let date = new Date(this.promo.starts_at)
            this.promo.starts_at = date.getFullYear() + "-" + ((date.getMonth()+1) < 10 ? "0"+(date.getMonth()+1) : (date.getMonth()+1)) + "-" + (date.getDate() < 10 ? "0"+date.getDate() : date.getDate()) + "T00:00:00.000Z"
            date = new Date(this.promo.ends_at)
            this.promo.ends_at = date.getFullYear() + "-" + ((date.getMonth()+1) < 10 ? "0"+(date.getMonth()+1) : (date.getMonth()+1)) + "-" + (date.getDate() < 10 ? "0"+date.getDate() : date.getDate()) + "T23:59:59.000Z"
            
            let result = ""
            if(this.props.action === "EDIT") {
                result = await updatePromotion(this.promo)
            }
            else {
                result = await createPromotion(this.promo);
            }

            if(result === "created" || result === "updated") {
                this.props.history.push("/planning");
            }
        }
    }

    cancelPromo() {
        this.props.history.push("/planning");
    }

    render() {
        const t = (value) => i18next.t(value)

        if(!this.state.loaded) {
            return <span>loading...</span>
        }
        else {
            return (
                <div className='col promo'>
                    <FieldTextPromo setPromo={this.setPromo.bind(this)} promo={this.promo}/>
                    <div className="col-12 p-0">
                        <p className="d-inline-block font-weight-bold font-size-25 c-opale m-0">{t("promo.multimedia_title")}</p><p className="d-inline-block c-red font-weight-bold font-size-16 m-0">&nbsp;*</p>
                    </div>
                    <div className="col-12 p-0">
                        <p className="font-weight-regular font-size-16 c-opale opacity-5">{t("promo.multimedia_subtitle")}</p>
                    </div>
                    <div className="col-lg-12 control-section e-tab-section p-0">
                        <Tabs setPromo={this.setPromo.bind(this)} promo={this.promo} action="EDIT"/>
                    </div>
                    <div className="col-12 p-0 mt-5">
                        <p className="d-inline-block font-weight-bold font-size-25 c-opale m-0">{t("promo.tag_title")}</p><p className="d-inline-block c-red font-weight-bold font-size-16 m-0">&nbsp;*</p>
                    </div>
                    <div className="col-12 p-0">
                        <p className="font-weight-regular font-size-16 c-opale opacity-5">
                            {t("promo.tag_subtitle")}
                        </p>
                    </div>
                    <div className="col-lg-12 control-section p-0">
                        <SitesList 
                            sites={this.data.sites} 
                            tags={this.data.tags} 
                            setPromo={this.setPromo.bind(this)}
                            promo={this.promo}
                            triggers={this.triggers}
                        />
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-8 justify-content-start">
                            <div id="error-promo" className="row m-0 error-promo pt-3 pb-2 px-3 bg-alert-red rounded-m d-none">
                                <div className="col-auto p-0">
                                    <img alt="" className="image" src={error}/>
                                </div>
                                <div className="col">
                                    <p className="m-0 font-weight-normal font-size-16 font-italic c-opale">
                                        {t("promo.promo_error")}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 d-flex justify-content-end">
                            <Button
                                value={t("promo.save_promo")}
                                cssClass={"save-promo bg-prussia c-white py-2 px-4 mt-4 mr-2 font-weight-bold font-size-16 no-shadow rounded-s"}
                                onClick={this.validateFields.bind(this)}
                            />
                            <Button
                                value={t("promo.cancel_promo")}
                                cssClass={"cancel-promo bg-white c-red py-2 px-4 mt-4 ml-2 font-weight-bold font-size-16 border-red no-shadow rounded-s"}
                                onClick={this.cancelPromo.bind(this)}
                            />     
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default FormPromo;
