import { Component } from "react"
import NavbarHorizontal from "../components/common/navbar/NavbarHorizontal"
import NavbarVertical from "../components/common/navbar/NavbarVertical"
import FluiditySiteDetail from "../components/fluiditySite/FluiditySiteDetail"

class FluiditySite extends Component {
    render() {
        const { history } = this.props;

        return (
            <div className="container-fluid">
                <div className="row">
                    <NavbarHorizontal history={history}/>
                </div>
                <div className="row">
                    <NavbarVertical history={history} tab="fluidity"/>
                    <FluiditySiteDetail history={history}/>
                </div>
            </div>
        )
        
    }
}

export default FluiditySite