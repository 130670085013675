import { MaskedTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { Component } from "react";

class MaskedTextBox extends Component {
    componentDidMount() {
        const span = document.createElement('span');
        span.className = 'e-icons';
        if(document.getElementById(this.props.id))
            document.getElementById(this.props.id).parentNode.appendChild(span)
    }

    render() {
        const { 
            cssClass,
            onChange,
            mask,
            value,
            id
        } = this.props;

        return (
            <MaskedTextBoxComponent
                mask={mask}
                cssClass={cssClass}
                onChange={onChange}
                value={value}
                id={id}
            />
        )
    }
}

export default MaskedTextBox