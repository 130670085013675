import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { Component } from "react";

class Switch extends Component {
    render() {
        const {
            change,
            cssClass,
            checked
        } = this.props;
        
        return (
            <SwitchComponent
                change={change}
                cssClass={cssClass}
                checked={checked}
            />
        )
    }
}

export default Switch