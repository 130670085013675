import React, { Component } from "react";
import NavbarVertical from "../components/common/navbar/NavbarVertical";
import NavbarHorizontal from "../components/common/navbar/NavbarHorizontal";
import PromotionsCalendar from "../components/promotionsPlanning/PromotionsCalendar"

class PromotionsPlanning extends Component {
  render() {
    const { history } = this.props;

    return (
      <div className="container-fluid">
        <div className="row">
          <NavbarHorizontal history={history}/>
        </div>
        <div className="row">
          <NavbarVertical history={history} tab="promo_planning"/>
          <div className="content-page w-100 h-auto bg-white rounded-l">
            <PromotionsCalendar history={history}/>
          </div>
        </div>
      </div>     
    );
  }
}

export default PromotionsPlanning;
