import React, { Component } from "react";
import HomeDashboard from "../components/home/HomeDashboard";
import NavbarHorizontal from "../components/common/navbar/NavbarHorizontal";
import NavbarVertical from "../components/common/navbar/NavbarVertical";


class Home extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
    };
  }

  render() {
    const { history } = this.props;

    return (
      <div className="container-fluid">
        <div className="row">
          <NavbarHorizontal history={history}/>
        </div>
        <div className="row">
          <NavbarVertical history={history} tab="dash"/>
          <div className="content-page w-100 h-auto bg-transparent rounded-l p-0">
            <HomeDashboard/>
          </div>
        </div>
      </div>     
    );
  }
}

export default Home;
