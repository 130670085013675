import i18next from "i18next";
import { Component } from "react";
import userTotal from "../../../images/total_people.svg"

class UsersTotal extends Component {
    render() {
        const t = (value) => i18next.t(value)

        const { usersRealtime, usersTotal } = this.props;

        return (
            <div className="row m-0 h-100">
                <div className="col-auto d-flex flex-column justify-content-center">
                    <img alt="" className="image-user-chart" src={userTotal}/>
                </div>
                <div className="col d-flex flex-column justify-content-center">
                    <p className="font-weight-bold font-size-29 c-prussia mb-2">
                        {t("fluidity_company.users_total")}
                    </p>
                    {usersRealtime === -1 ? 
                    <p className="font-weight-bold font-size-38 c-opale mb-0">
                        {t("fluidity_company.no_fluidity")}
                    </p> :
                    <p className="font-weight-bold font-size-55 c-opale mb-0">
                        {usersTotal}
                    </p>}
                </div>
            </div>
        )
    }
}

export default UsersTotal