import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { Component } from "react";

class TextBox extends Component {
    render() {
        const { 
            cssClass,
            id,
            multiline,
            ref,
            value,
            onChange
        } = this.props;

        return (
            <TextBoxComponent
                cssClass={cssClass}
                id={id}
                multiline={multiline}
                ref={ref}
                value={value}
                onChange={onChange}
            />
        )
    }
}

export default TextBox