import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import RestaurantTriggerList from "./RestaurantTriggerList";

class Trigger extends Component {
    render() {
        const t = this.props.t

        const { restaurants, setTrigger, unsetTrigger } = this.props;

        return (
            <div className="row w-100 m-0">
                <div className="col-12 pl-custom-header">
                    <p className="font-weight-regular font-size-16 c-opale opacity-5">
                        {t("promo.fluidity_trigger_subtitle")}
                    </p>
                </div>
                {restaurants.map((restaurant, key) => {
                    return restaurant.regions ?
                        <RestaurantTriggerList restaurant={restaurant} setTrigger={setTrigger} unsetTrigger={unsetTrigger} promo={this.props.promo} key={key}/>
                    : null
                })}
            </div>
        )
    }
}

export default withTranslation()(Trigger)