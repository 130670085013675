import React, { Component } from "react"
import { Router, Route, Switch } from "react-router-dom"
import * as History from "history/"
import * as Pages from "../pages/index"

const history = History.createBrowserHistory()
const ROUTES = [
  {
    path: "/login",
    component: Pages.Login
  },
  {
    path: "/planning",
    component: Pages.PromotionsPlanning
  },
  {
    path: "/promo",
    component: Pages.Promo
  },
  {
    path: "/fluidity/site",
    component: Pages.FluiditySite
  },
  {
    path: "/fluidity",
    component: Pages.FluidityCompany
  },
  {
    path: "/",
    component: Pages.Home
  },
]

class AppContainer extends Component {
  render() {
    return (
      <Router history={history}>
        <div>
          <Switch>
            {ROUTES.map(
              ({ path, component: Comp }) => {
                return (
                  <Route
                    path={path}
                    key={path}
                    render={props => {
                      return (
                      <Comp
                        {...this.props} 
                        {...props}
                      />
                      )
                    }}
                  />
                )
              }
            )}
          </Switch>
        </div>
      </Router>
    )
  }
}

export default AppContainer
