import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { Component } from "react";

class Button extends Component {
    render() {
        const {
            cssClass,
            value,
            onClick
        } = this.props;

        return (
            <ButtonComponent 
                cssClass={cssClass}
                onClick={onClick}
            >
                {value}
            </ButtonComponent>
        )
    }
}

export default Button