import React, { Component } from "react";
import NavbarVertical from "../components/common/navbar/NavbarVertical";
import NavbarHorizontal from "../components/common/navbar/NavbarHorizontal";
import FormPromo from "../components/promo/FormPromo";
import i18next from "i18next";

class Promo extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      action: this.props.history.location.pathname === "/promo/new" ? "NEW" : "EDIT"
    };
  }

  render() {
    const { history } = this.props;
    const { action } = this.state;
    const t = (value) => i18next.t(value)

    return (
      <div className="container-fluid">
        <div className="row">
          <NavbarHorizontal history={history}/>
        </div>
        <div className="row">
          <NavbarVertical history={history} tab="promo_planning"/>
          <div className="content-page w-100 h-auto bg-white rounded-l">
            <div className='row m-0'>
              <div className="col font-size-35 font-weight-bold c-opale mb-4">
                {action === "EDIT" ? t("promo.title_edit") : t("promo.title_add")}
              </div>
            </div>
            <FormPromo
              action={action}
              history={history}
            />
          </div>
        </div>
      </div>     
    );
  }
}

export default Promo;
