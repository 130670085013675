import i18next from "i18next";
import { Component } from "react";
import Button from "../wrapper/button/Button";
import Schedule from "./schedule/Schedule";

class PromotionsCalendar extends Component {
    gotoPromo() {
        this.props.history.push("/promo/new");
    }

    render() {
        const { history } = this.props;
        const t = (value) => i18next.t(value)

        return (
            <>
                <div className='row m-0 mb-3'>
                    <div className="col font-size-35 font-weight-bold c-opale mb-2">
                        {t("promotions_planning.title")}
                    </div>
                    <div className="col-auto">
                        <Button
                            onClick={this.gotoPromo.bind(this)}
                            cssClass="bg-prussia c-white px-4 py-2"
                            value={t("promotions_planning.button_add")}
                        />
                    </div>
                </div>
                <Schedule
                    history={history}
                />
            </>
        )
    }
}

export default PromotionsCalendar