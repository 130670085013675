import { ColorPickerComponent } from "@syncfusion/ej2-react-inputs";
import { Component } from "react";
import "./form-promo-color-picker.css"

class ColorPicker extends Component {
    render() {
        const {
            modeSwitcher,
            cssClass,
            value,
            change,
            id,
            ref
        } = this.props;

        return (
            <ColorPickerComponent
                change={change}
                modeSwitcher={modeSwitcher} 
                cssClass={cssClass}
                value={value}
                id={id}
                ref={ref}
            />
        )
    }
}

export default ColorPicker