import i18next from "i18next";
import React, { Component } from "react";
import Presets from "./Presets";

class TypesPromoList extends Component {
    constructor(...args) {
        super(...args)

        this.newRestaurantModel= {
            place_id: 0,
            tag_id: 0,
            region_id: null,
            capacity_level: null
        }
    }

    componentDidMount() {
        const { tags, promo } = this.props;

        promo.restaurants.forEach(restaurant => {
            let tagName = ""
            tags.forEach(tag => {
                if(tag.id === restaurant.tag_id) {
                    tagName = tag.name
                }
            })
            const tagRestaurantChecked = document.querySelector("div[aria-checked='false'] input[id^='typepromo-'][id$='-"+restaurant.place_id+"-"+tagName+"']")
            if(tagRestaurantChecked) {
                const checkboxSplitID = tagRestaurantChecked.id.split("-");
                const restaurantChecked = document.querySelector("div[aria-checked='false'] input[id='restaurant-"+checkboxSplitID[1]+"-"+checkboxSplitID[2]+"']")
                const siteChecked = document.querySelector("div[aria-checked='false'] input[id='site-"+checkboxSplitID[1]+"']");

                if(siteChecked){
                    siteChecked.click()
                }
                if(restaurantChecked){
                    restaurantChecked.click()
                }
                if(tagRestaurantChecked){
                    tagRestaurantChecked.click()
                }

                tags.forEach(tag => {
                    const accordionSite = document.querySelector("input[id='site-"+checkboxSplitID[1]+"']").parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode

                    const numberOfAllTagMustBeChecked = accordionSite.querySelectorAll("input[id^='typepromo-"+checkboxSplitID[1]+"-'][id$='-"+tag.name+"']").length
                    const numberOfAllTagChecked = accordionSite.querySelectorAll("div[aria-checked='true'] input[id^='typepromo-"+checkboxSplitID[1]+"-'][id$='-"+tag.name+"']").length
                    if(numberOfAllTagChecked === numberOfAllTagMustBeChecked) {
                        if(document.querySelector("div[aria-checked='false'] input[id='site-"+checkboxSplitID[1]+"-"+tag.name+"']"))
                            document.querySelector("div[aria-checked='false'] input[id='site-"+checkboxSplitID[1]+"-"+tag.name+"']").click();
                    }
                })

                tags.forEach(tag => {
                    const numberOfAllTagMustBeChecked = document.querySelectorAll("input[id^='typepromo-'][id$='-"+tag.name+"']").length
                    const numberOfAllTagChecked = document.querySelectorAll("div[aria-checked='true'] input[id^='typepromo-'][id$='-"+tag.name+"']").length
                    if(numberOfAllTagChecked === numberOfAllTagMustBeChecked) {
                        if(document.querySelector("div[aria-checked='false'] input[id='company-"+tag.name+"']"))
                            document.querySelector("div[aria-checked='false'] input[id='company-"+tag.name+"']").click();
                    }
                })
            }
        })
    }

    checkTheBoxPreset(event) {
        const { addRestaurant, deleteRestaurant } = this.props;
        const checkboxSplitID = event.event.currentTarget.id.split("-")
            
        if(event.checked) {
            if(checkboxSplitID[3] === "All") {
                document.querySelectorAll("input:not([id$='All'])[id^='"+checkboxSplitID[0]+"-"+checkboxSplitID[1]+"-"+checkboxSplitID[2]+"-']").forEach(presetToLock => {
                    presetToLock.parentNode.parentNode.classList.add("disabled");
                })
            }
            else {
                document.querySelector("input[id='"+checkboxSplitID[0]+"-"+checkboxSplitID[1]+"-"+checkboxSplitID[2]+"-All']").parentNode.parentNode.classList.add("disabled");
            }

            if(document.querySelector("div[class~='trigger-site-"+checkboxSplitID[1]+"']"))
                document.querySelector("div[class~='trigger-site-"+checkboxSplitID[1]+"']").classList.remove("d-none")
            if(document.getElementById("restaurant-trigger-"+checkboxSplitID[2]))
                document.getElementById("restaurant-trigger-"+checkboxSplitID[2]).classList.remove("d-none")

            addRestaurant(this.createRestaurantJSON(checkboxSplitID[2], checkboxSplitID[3]))
        }
        else {
            if(checkboxSplitID[3] === "All") {
                document.querySelectorAll("input:not([id$='All'])[id^='"+checkboxSplitID[0]+"-"+checkboxSplitID[1]+"-"+checkboxSplitID[2]+"-']").forEach(presetToLock => {
                    presetToLock.parentNode.parentNode.classList.remove("disabled");
                })
            }
            else {
                const divCheckBox = document.querySelector("div[class*='accordion-restaurant-"+checkboxSplitID[2]+"']").querySelectorAll("div[aria-checked='true'][class~='check-types-promo']")
                if(divCheckBox.length === 0) {
                    document.querySelector("div[class*='accordion-restaurant-"+checkboxSplitID[2]+"']").querySelector("input[id='typepromo-"+checkboxSplitID[1]+"-"+checkboxSplitID[2]+"-All']").parentNode.parentNode.classList.remove("site");
                    document.querySelector("div[class*='accordion-restaurant-"+checkboxSplitID[2]+"']").querySelector("input[id='typepromo-"+checkboxSplitID[1]+"-"+checkboxSplitID[2]+"-All']").parentNode.parentNode.classList.remove("disabled");
                }
            }

            if(document.querySelectorAll("div[aria-checked='true'] input[id^='"+checkboxSplitID[0]+"-"+checkboxSplitID[1]+"-"+checkboxSplitID[2]+"-']").length === 0) {
                if(document.querySelectorAll("div[class~='trigger-site-"+checkboxSplitID[1]+"'] div[id^='restaurant-trigger-"+checkboxSplitID[2]+"'] .e-switch-inner.e-switch-active").length > 0) {
                    document.querySelectorAll("div[class~='trigger-site-"+checkboxSplitID[1]+"'] div[id^='restaurant-trigger-"+checkboxSplitID[2]+"'] .e-switch-inner.e-switch-active").forEach(flagTriggerOn => {
                        flagTriggerOn.click();
                    })
                }
                if(document.getElementById("restaurant-trigger-"+checkboxSplitID[2]))
                    document.getElementById("restaurant-trigger-"+checkboxSplitID[2]).classList.add("d-none")
            }
            if(document.querySelectorAll("div[class~='trigger-site-"+checkboxSplitID[1]+"']").length !== 0 && document.querySelectorAll("div[class~='trigger-site-"+checkboxSplitID[1]+"'] div[id^='restaurant-trigger-']:not([class~='d-none'])").length === 0) {
                document.querySelector("div[class~='trigger-site-"+checkboxSplitID[1]+"']").classList.add("d-none")
            }

            deleteRestaurant(this.createRestaurantJSON(checkboxSplitID[2], checkboxSplitID[3]))
        }
    }

    createRestaurantJSON(restaurantID, tagName) {
        const { tags } = this.props;

        const restaurantJSON = this.newRestaurantModel;
        tags.forEach(tag => {
            if(tag.name === tagName) {
                restaurantJSON.place_id = restaurantID
                restaurantJSON.tag_id = tag.id
            }
        })
        return restaurantJSON
    }

    render() {
        const t = (value) => i18next.t(value)

        const { tags, siteID, restaurantID } = this.props;

        return (
            <div className="row w-100 m-0 px-3 py-2">
                <div className="col-12">
                    <p className="font-weight-bold font-size-24 c-prussia mb-1">{t("promo.preset_title")}</p>
                </div>
                <div className="col-12 mb-2">
                    <Presets
                        cssClass="check-types-promo "
                        tags={tags}
                        refID={"typepromo-"+siteID+"-"+restaurantID}
                        onClick={this.checkTheBoxPreset.bind(this)}
                    />
                </div>
            </div>
        )
    }
}

export default TypesPromoList