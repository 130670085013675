import { Component } from "react";
import { SliderComponent } from "@syncfusion/ej2-react-inputs";
import "./multimedia-cropper.css";

class Slider extends Component {
    constructor(...args) {
        super(...args)

        this.state = {
            zoom: this.props.value
        }
    }

    render() {
        const {
            min,
            max,
            step,
            change,
            type
        } = this.props;

        const {
            zoom
        } = this.state;

        return (
            <div className="slider-wrap">
                <SliderComponent
                    value={zoom}
                    min={min}
                    max={max}
                    step={step}
                    change={(zoom) => change(zoom)}
                    type={type}
                />
            </div>
        )
    }
}

export default Slider