import i18next from "i18next";
import React, { Component } from "react";
import { setLanguage } from "../../../../api/fetch";
import flagEng from "../../../../images/eng.png";
import flagFr from "../../../../images/fr.png";
import flagIta from "../../../../images/ita.png";
import "./language-selector.css";

class LanguageSelector extends Component {
  constructor(...args) {
    super(...args);
    
    this.state = {
      imageActive: "",
      langActive: ""
    };

    this.languages = [
      {name: "fr", flag: flagFr},
      {name: "it", flag: flagIta},
      {name: "en", flag: flagEng},
    ];
  }

  componentDidMount() {
    this.changeLang(i18next.language.split("-")[0])
  }

  changeLang(lang) {
    i18next.changeLanguage(lang)
    this.setState({
      langActive: lang,
      imageActive: this.selectActiveFlag(lang)
    })
  }

  handleClick(lang) {
    this.changeLang(lang);

    const data = new FormData();
    data.append("language", lang);
    setLanguage(data);
  }

  selectActiveFlag(langActive) {
    switch (langActive) {
      case "it":
        return flagIta;
      case "fr":
        return flagFr;
      case "en":
        return flagEng;
      default:
        return flagEng;
    }
  }

  render() {
    return (   
        <div className="lang-list">
          {this.languages.map((language) => {
            return (
              this.state.langActive !== language.name ? 
                <button 
                  id={language.name}
                  key={language.name}
                  className="lang-item row m-0" 
                  onClick={this.handleClick.bind(this, language.name)} 
                >
                  <img alt="" className="col-12 py-2 px-3" src={language.flag} />
                </button>
              : null
            );
          })}
          {this.languages.map((language) => {
            return (
              this.state.langActive === language.name ? 
                <button 
                  id={language.name}
                  key={language.name}
                  className="lang-item active row m-0" 
                >
                  <img alt="" className="col-12 py-2 px-3" src={language.flag} />
                </button>
              : null
            );
          })}
        </div>
    
    );
  }
}

export default LanguageSelector;
